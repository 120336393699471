import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { baseResponse } from "../../utils/models";
import { getLocalStorage, setLocalStorage } from "../../utils/helper";

class BaseService {
    protected readonly instance: AxiosInstance;
    private setLoading: (loading: boolean) => void;
    private isRefreshing: boolean = false;
    private failedQueue: any[] = [];

    constructor(setLoading: (loading: boolean) => void) {
        this.setLoading = setLoading;
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.instance.interceptors.request.use(
            (config) => {
                this.setLoading(true);
                const token = getLocalStorage('accessToken');
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                this.setLoading(false);
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => {
                this.setLoading(false);
                return response;
            },
            async (error) => {
                this.setLoading(false);
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    if (this.isRefreshing) {
                        return new Promise((resolve, reject) => {
                            this.failedQueue.push({ resolve, reject });
                        }).then(token => {
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            return this.instance(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        });
                    }

                    originalRequest._retry = true;
                    this.isRefreshing = true;

                    const refreshToken = getLocalStorage('refreshToken');

                    try {
                        const response = await this.refresh(refreshToken);

                        if (!response) {
                            console.log('Cannot refresh token.');
                            return Promise.reject(error);
                        }

                        setLocalStorage('accessToken', response.accessToken);
                        setLocalStorage('refreshToken', response.refreshToken);
                        axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`;

                        originalRequest.headers['Authorization'] = `Bearer ${response.accessToken}`;
                        this.processQueue(null, response.accessToken);
                        return this.instance(originalRequest);
                    } catch (refreshError) {
                        this.processQueue(refreshError, null);
                        return Promise.reject(refreshError);
                    } finally {
                        this.isRefreshing = false;
                    }
                }

                return Promise.reject(error);
            }
        );
    }

    private processQueue(error: any, token: string | null = null) {
        this.failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        this.failedQueue = [];
    }

    get = async (url: string, params?: any): Promise<baseResponse> => {
        try {
            const response = await this.instance.get(url, { params });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    post = async (url: string, payload: any): Promise<baseResponse> => {
        try {
            const response = await this.instance.post(url, payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    put = async (url: string, payload?: any): Promise<baseResponse> => {
        try {
            const response = await this.instance.put(url, payload);
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    delete = async (url: string, payload?: any): Promise<baseResponse> => {
        try {
            const response = await this.instance.delete(url, { data: payload });
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    setToken = (token: string) => {
        setLocalStorage('accessToken', token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    };

    private refresh = async (refreshToken: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/authen/refresh`, {
                headers: {
                    'Authorization': `Bearer ${refreshToken}`,
                },
            });
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

let baseServiceInstance: BaseService | null = null;

export const getBaseService = (setLoading: (loading: boolean) => void) => {
    if (!baseServiceInstance) {
        baseServiceInstance = new BaseService(setLoading);
    }
    return baseServiceInstance;
};

export default getBaseService;
