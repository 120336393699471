import React from 'react';
import './PaymentDetail.css';

const PaymentDetail: React.FC = () => {
  return (
    <section className="payment-detail-wrapper">
      <div className="block-payment-detail">
        <div className="block-payment-amount container-items">
          <div className="payment-amount-title">
            <hr className="line" />
            <p className="title-name">
              ยอดชำระเงิน
            </p>
            <hr className="line" />
          </div>
          <div className="total-payment">
            <p className="total-payment-title">ราคารวม</p>
            <p className="total-payment-number">3,300 บาท</p>
          </div>
        </div>
      </div>

      <div className="block-payment-detail">
        <div className="block-payment-slip container-items">
          <div className="payment-slip-title">
            <hr className="line" />
            <p className="title-name">
              ชำระเงินและแนบสลิป
            </p>
            <hr className="line" />
          </div>
          <div className="payment-slip-details">
            <div className="bank-image">
              <img className="logo-bank" src="/assets/images/logo-k-bank.png" alt="logo-k-bank" />
            </div>
            <div className="bank-details">
              <h2 className="bank-details-title">เลขที่บัญชี</h2>
              <p className="bank-details-content">129-844407-7</p>
              <h2 className="bank-details-title">ชื่อบัญชี</h2>
              <p className="bank-details-content">บริษัท คอนกรีต เดลิเวอรี่ จำกัด</p>
            </div>
          </div>
        </div>
        <div className="block-btn container-items">
          <div className="admin-account-number">
            <button className="btn btn-copy-account-number">
              <img className="icon icon-park-outline-copy-link" src="/assets/icons/icon-park-outline-copy-link.svg" alt="icon-park-outline-copy-link" />
              คัดลอกเลขบัญชี
            </button>
            <button className="btn btn-admin">
              <img className="icon icon-phone-outline" src="/assets/icons/icon-phone-outline.svg" alt="icon-phone-outline" />
              ติดต่อแอดมิน
            </button>
          </div>
          <div className="attach-slip">
            <button className="btn btn-attach-slip">แนบสลิป</button>
            {/* slip */}
            {/* <img className="image-slip" src="/assets/images/example-slip.png" alt="image-slip" /> */}
          </div>
        </div>
        <div className="block-payment-slip-address container-items">
          <div className="payment-slip-address-title">
            <hr className="line" />
            <p className="address-title-name">
              ที่อยู่
            </p>
            <hr className="line" />
          </div>
          <div className="payment-slip-address-details">
            <div className="address-image">
              <img className="image-address" src="/assets/images/image-address.png" alt="image-address" />
            </div>
            <div className="bank-details">
              <h2 className="bank-details-title">บริษัท</h2>
              <p className="bank-details-content">528 นากลาง , บึงใหญ่ , นครปฐม 521300</p>
            </div>
          </div>
          <div className="block-btn-edit-address">
            <button className="btn btn-edit">แก้ไข</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PaymentDetail;