import React, { useRef } from 'react';
import "./profileEdit.css";

const ProfileEdit: React.FC = () => {
  const fileUploaderRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = () => {
    if (fileUploaderRef.current) {
      fileUploaderRef.current.click();
    }
  };

  return (
    <section className="profile-edit-wrapper bg-white">
      <div className="block-profile-edit lg:container lg:mx-auto">
        <h2 className="title-profile-edit">นโยบายความเป็นส่วนตัว</h2>
        <div className="block-avatar-profile-edit">
          <button
            className="btn btn-edit-avatar group"
            type="button"
            onClick={handleFileChange}
          >
            <img
              className="avatar-profile-edit"
              src="/assets/icons/icon-user.svg"
              alt="icon-user"
            />
            <span className="block-icon-edit group-hover:brightness-200 hidden group-hover:block max-[430px]:group-hover:hidden">
              <img
                className="icon-edit"
                src="/assets/icons/icon-edit.svg"
                alt="icon-edit"
              />
            </span>
          </button>
          <input
            type="file"
            ref={fileUploaderRef}
            className="file-input hidden"
            accept="image/*"
            aria-label="อัปโหลดรูปโปรไฟล์"
          />
        </div>
        <form>
          <div className="block-form-profile-edit">
            <div className="form-contact">
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">ชื่อ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกชื่อ"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">นามสกุล</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกนามสกุล"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">เบอร์ติดต่อ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกเบอร์ติดต่อ"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">อีเมล</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกอีเมล"
                  className="input input-bordered"
                />
              </label>
            </div>
          </div>
        </form>
        <div className="btn-group py-5">
          <button className="btn btn-profile-edit">
            บันทึกการแก้ไข
          </button>
        </div>
      </div>
    </section>
  )
}

export default ProfileEdit;