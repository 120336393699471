import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiServices } from "../../services/endpoints/ApiEndpoint";
import { removeLocalStorage, setLocalStorage } from "../../utils/helper";
import { AdditionalTypeString, payloadResetPassword } from "../../utils/models";
import "./resetPassword.css";

const ResetPassword: React.FC = () => {
  const { ResetPasswordAsync } = useApiServices()
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<payloadResetPassword & AdditionalTypeString>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(false);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] =
    useState<boolean>(false);

  const onSubmitResetPassword = async (
    data: payloadResetPassword & AdditionalTypeString
  ) => {
    try {
      setIsLoading(true);
      delete data.confirmPassword;

      const payload: payloadResetPassword = {
        password: data.password,
      };
      const { result } = await ResetPasswordAsync(payload);
      setIsLoading(false);
      if (result.isSuccess) {
        removeLocalStorage("phoneNumber");
        setLocalStorage("accessToken", result?.accessToken ?? null);
        setLocalStorage("refreshToken", result?.refreshToken ?? null);
        setLocalStorage("expireTime", result?.expireTime ?? null);
        navigate("/");
      } else {
        toast.error(`${result?.message ?? "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"}`, {
          position: "bottom-left",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("reset password", error);
      toast.error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง", {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const onCancel = () => {
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    removeLocalStorage("expireTime");
    removeLocalStorage("phoneNumber");
    navigate("/login");
  };

  return (
    <>
      <section className="block-reset-password-wrapper">
        <img
          className="bg-form"
          src="/assets/images/bg-city-01.png"
          alt="bg-city-01"
        />
        <div className="block-reset-password">
          <div className="image-logo">
            <img
              className="logo-reset-password"
              src="/assets/logo/logo-brand-vertical.png"
              alt="logo-brand-vertical"
            />
          </div>
          <h1 className="title">รีเซ็ตรหัสผ่าน</h1>
          <form onSubmit={handleSubmit(onSubmitResetPassword)}>
            <label className="form-control w-full form-password-label">
              <div className="label">
                <span className="label-text">รหัสผ่านใหม่</span>
              </div>
            </label>
            <label className="input input-bordered form-password">
              <input
                {...register("password", { required: "กรุณากรอกรหัสผ่าน" })}
                className="grow"
                type={!hiddenPassword ? "password" : "text"}
                placeholder="กรอกรหัสผ่าน"
                autoComplete="current-password"
              />
              {!hiddenPassword ? (
                <button
                  type="button"
                  className="btn btn-ghost hover:bg-transparent hidden-password"
                  onClick={(e) => setHiddenPassword(true)}
                >
                  <img
                    className="icon-eye-hidden"
                    src="/assets/icons/icon-eye-hidden.svg"
                    alt="icon-eye-hidden"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-ghost hover:bg-transparent view-password"
                  onClick={(e) => setHiddenPassword(false)}
                >
                  <img
                    className="icon-eye-view"
                    src="/assets/icons/icon-eye-view.svg"
                    alt="icon-eye-view"
                  />
                </button>
              )}
            </label>
            {errors?.password && (
              <span className="text-error text-sm -mt-2">
                {errors?.password?.message}
              </span>
            )}
            <label className="form-control w-full form-password-label">
              <div className="label">
                <span className="label-text">ยืนยันรหัสผ่านใหม่</span>
              </div>
            </label>
            <label className="input input-bordered form-password">
              <input
                {...register("confirmPassword", {
                  required: "กรุณากรอกรหัสผ่าน",
                  validate: (val: string) => {
                    if (watch("password") !== val) {
                      return "รหัสผ่านของคุณไม่ตรงกัน กรุณาป้อนรหัสผ่านใหม่อีกครั้ง";
                    }
                  },
                })}
                className="grow"
                type={!hiddenConfirmPassword ? "password" : "text"}
                placeholder="กรอกรหัสผ่าน"
                autoComplete="current-password"
              />
              {!hiddenConfirmPassword ? (
                <button
                  type="button"
                  className="btn btn-ghost hover:bg-transparent hidden-password"
                  onClick={(e) => setHiddenConfirmPassword(true)}
                >
                  <img
                    className="icon-eye-hidden"
                    src="/assets/icons/icon-eye-hidden.svg"
                    alt="icon-eye-hidden"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-ghost hover:bg-transparent view-password"
                  onClick={(e) => setHiddenConfirmPassword(false)}
                >
                  <img
                    className="icon-eye-view"
                    src="/assets/icons/icon-eye-view.svg"
                    alt="icon-eye-view"
                  />
                </button>
              )}
            </label>
            {errors?.confirmPassword && (
              <span className="text-error text-sm -mt-2">
                {errors?.confirmPassword?.message}
              </span>
            )}
            <button type="button" className="btn btn-cancel" onClick={onCancel}>
              ยกเลิก
            </button>
            <button
              type="submit"
              className="btn btn-confirm disabled:bg-slate-500"
              disabled={isLoading}
            >
              ยืนยัน
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
