import React, { useState } from "react";
import "./Header.css";
interface HeaderProps {
  pathname: string;
}

const Header: React.FC<HeaderProps> = ({ pathname }) => {
  const [numberProducts, setNumberProducts] = useState<number>(1);

  const checkPathForHide = (pathname: string) => {
    switch (pathname) {
      case "/login":
        return "hidden";
      case "/register-otp":
        return "hidden";
      case "/reset-password-otp":
        return "hidden"
      case "/reset-password":
        return "hidden";
      case "/register":
        return "hidden";
      case "/welcome":
        return "hidden";
      default:
        return ""
    }
  }

  let mockLength = 0;

  const increaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber < 999 ? prevNumber + 1 : 999));
  };

  const decreaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber > 1 ? prevNumber - 1 : 1));
  };

  return (
    <nav className={`block-header-wrapper ${checkPathForHide(pathname)}`}>
      <div className="block-navbar-menu lg:container lg:mx-auto">
        <div className="navbar-menu-left">
          <div className="block-logo-navbar">
            <a href="/" className="btn-menu">
              <img
                className="logo-navbar"
                src="/assets/logo/logo-brand-horizontal.png"
                alt="logo-brand-horizontal"
              />
            </a>
          </div>
          <ul className="menu-left-list">
            <li>
              <a href="/location" className="btn-menu">
                จัดการตำแหน่งที่ตั้ง
              </a>
            </li>
            <li>
              <a href="/order" className="btn-menu">
                ประวัติการสั่งซื้อ
              </a>
            </li>
            <li>
              <a href="/contact" className="btn-menu">
                ติดต่อ
              </a>
            </li>
          </ul>
        </div>

        <div className="navbar-menu-right">
          <ul className="menu-right-list">
            <li>
              <label className="input input-bordered input-search">
                <input type="text" className="grow" placeholder="Search" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </label>
            </li>
            <li>
              <div className="btn-menu">
                <div className="flex-none">
                  <div className="dropdown dropdown-end">
                    <div tabIndex={0} role="button" className="btn-menu-bell">
                      <div className="indicator">
                        <img
                          className="icon icon-cart"
                          src="/assets/icons/icon-cart.svg"
                          alt="icon-cart"
                        />
                        <span className="badge badge-sm indicator-item">8</span>
                      </div>
                    </div>
                    <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">
                      <div className="card-body">
                        <span className="title font-bold text-lg text-center">ตะกร้าสินค้า</span>
                        <div className="cart">
                          <div className="card-items">
                            <div className="card-item">
                              <div className="card-item-image">
                                <img
                                  className="image item-image"
                                  src="/assets/images/no-image-product.png"
                                  alt="image-product"
                                />
                              </div>
                              <div className="card-item-name">
                                <p className="card-name">เหล็ก WF-BEAM 300x150x6.5x9มม. 9 เมตร SS400</p>
                                <div className="block-card-number-products">
                                    <div className="card-number-products">
                                      <label className="input">
                                        <button className="btn decrease-number" onClick={decreaseNumber}>
                                          -
                                        </button>
                                        <input
                                          type="number"
                                          placeholder="999"
                                          className="input input-number-products"
                                          value={numberProducts}
                                          onChange={e => setNumberProducts(Math.min(parseInt(e.target.value, 10), 999))}
                                        />
                                        <button className="btn increase-number" onClick={increaseNumber}>
                                          +
                                        </button>
                                      </label>
                                    </div>
                                </div>
                                <p className="product-price-unit">1,000.00 THB</p>
                              </div>
                            </div>
                            <button className="btn btn-delete">
                              <img
                                className="icon icon-delete"
                                src="/assets/icons/icon-delete.svg"
                                alt="icon-delete"
                              />
                            </button>
                          </div>
                        </div>
                        <p className="total">
                          <span>ยอดรวม</span>
                          <span>3,000 THB</span>
                        </p>
                        <div className="btn-group">
                          <a href="/order" className="btn btn-view">
                            <span>8 รายการ</span>|
                            <span>ดูรายละเอียด</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="dropdown dropdown-end btn-menu">
                <div tabIndex={0} role="button" className="btn-menu-bell">
                  <div className="indicator">
                    <img
                      className="icon icon-bell"
                      src="/assets/icons/icon-bell.svg"
                      alt="icon-bell"
                    />
                    <span className="badge badge-sm indicator-item">5</span>
                  </div>
                </div>
                <div tabIndex={0} className="mt-[2rem] z-[1] card card-compact dropdown-content shadow-[0_2px_24px_0px_#0000001A]">
                  <div className="card-body">
                    <div role="tablist" className="tabs tabs-bordered">
                      <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="ข่าวสาร" defaultChecked />
                      <div role="tabpanel" className="tab-content">
                        <div className="card-message shadow-[0_2px_8px_0px_#0000001A]">
                          <div className="card-message-icon">
                            <img
                              className="icon icon-status shadow-[0_2px_24px_0px_#0000001A]"
                              src="/assets/icons/icon-vehicle-truck-cube-orange.svg"
                              alt="icon-vehicle-truck-cube-orange"
                            />
                          </div>
                          <div className="card-message-content">
                            <h2 className="message-content-title">
                              เหล็กสวย มาตรฐานดี ต้องที่ไทยพิพัฒน์
                              (ถ.มะลิวัลย์)
                            </h2>
                            <p className="message-content-date-time">
                              23/05/2566 17:11:04
                            </p>
                          </div>
                          <div className="card-message-btn">
                            <details className="dropdown">
                              <summary className="btn">
                                <img
                                  className="icon icon-three-dots-vertical"
                                  src="/assets/icons/icon-three-dots-vertical.svg"
                                  alt="icon-three-dots-vertical"
                                />
                              </summary>
                              <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box w-52">
                                <li><a>อ่านแล้ว</a></li>
                                <li><a>ลบ</a></li>
                              </ul>
                            </details>
                          </div>
                        </div>
                      </div>

                      <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="การขนส่ง" />
                      <div role="tabpanel" className="tab-content">
                        <div className="card-transportation shadow-[0_2px_8px_0px_#0000001A]">
                          <div className="card-message-icon">
                            <img
                              className="icon icon-status shadow-[0_2px_24px_0px_#0000001A]"
                              src="/assets/icons/icon-vehicle-truck-cube-orange.svg"
                              alt="icon-vehicle-truck-cube-orange"
                            />
                          </div>
                          <div className="card-message-content">
                            <h2 className="message-content-title">
                              Order #20231130001
                              <span className="status">กำลังจัดส่ง</span>
                            </h2>
                            <p className="message-content-date-time">
                              23/05/2566 17:11:04
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a href="/profile" className="btn-menu">
                <div className="flex-none">
                  <div className="indicator">
                    <img
                      className="icon icon-user"
                      src="/assets/icons/icon-user.svg"
                      alt="icon-user"
                    />
                  </div>
                </div>
              </a>
            </li>
          </ul>

          {/*toggle Menu Mobile */}
          <div className="drawer drawer-end btn-toggle-menu-mobile">
            <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
            <div className="drawer-content">
              {/* Page content here */}
              <label htmlFor="my-drawer-4" className="drawer-button btn btn-ghost p-0">
                <img
                  className="logo-navbar"
                  src="/assets/icons/icon-hamburger.svg"
                  alt="icon-hamburger"
                />
              </label>
            </div>
            <div className="drawer-side">
              <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
              <ul className="menu">
                {/* Sidebar content here */}
                <label htmlFor="my-drawer-4" className="drawer-button btn-toggle-menu-mobile">
                  <img
                    className="btn-toggle-menu-mobile"
                    src="/assets/icons/icon-btn-next-bg-white.svg"
                    alt="icon-btn-next-bg-white"
                  />
                </label>
                <li>
                  <div className="profile">
                    <div className="block-avatar">
                      {mockLength !== 0 ? (
                        <img
                          className="avatar"
                          src="/assets/icons/icon-btn-next-bg-white.svg"
                          alt="icon-btn-next-bg-white"
                        />
                      ) : (
                        <img
                          className="avatar"
                          src="/assets/icons/icon-user.svg"
                          alt="icon-user"
                        />
                      )}
                    </div>
                    <span className="block-name-email">
                      <p className="name">มานี มีใจ</p>
                      <p className="email">Maneemeejai@gmail.com</p>
                    </span>
                  </div>
                </li>
                <li>
                  <a href="/profile" className="block-list-menu">
                    <div className="icon-menu">
                      <img
                        className="icon-item icon-personal-information"
                        src="/assets/icons/icon-personal-information.svg"
                        alt="icon-personal-information"
                      />
                    </div>
                    <p className="name-menu">ข้อมูลส่วนตัว</p>
                  </a>
                </li>
                <li>
                  <a href="/order" className="block-list-menu">
                    <div className="icon-menu">
                      <img
                        className="icon-item icon-time-history"
                        src="/assets/icons/icon-time-history.svg"
                        alt="icon-time-history"
                      />
                    </div>
                    <p className="name-menu">ประวัติการสั่งซื้อ</p>
                  </a>
                </li>
                <li>
                  <a href="/location" className="block-list-menu">
                    <div className="icon-menu">
                      <img
                        className="icon-item icon-manage-location"
                        src="/assets/icons/icon-manage-location.svg"
                        alt="icon-manage-location"
                      />
                    </div>
                    <p className="name-menu">จัดการตำแหน่งที่ตั้ง</p>
                  </a>
                </li>
                <li>
                  <a href="/notification" className="block-list-menu">
                    <div className="icon-menu">
                      <img
                        className="icon-item icon-bell"
                        src="/assets/icons/icon-bell.svg"
                        alt="icon-bell"
                      />
                    </div>
                    <p className="name-menu">แจ้งเตือน</p>
                  </a>
                </li>
                <li>
                  <a href="/profile" className="block-list-menu">
                    <div className="icon-menu">
                      <img
                        className="icon-item icon-setting"
                        src="/assets/icons/icon-setting.svg"
                        alt="icon-setting"
                      />
                    </div>
                    <p className="name-menu">การตั้งค่า</p>
                  </a>
                </li>
                <li>
                  <a href="/contact" className="block-list-menu">
                    <div className="icon-item icon-menu">
                      <img
                        className="icon-item icon-contact"
                        src="/assets/icons/icon-contact.svg"
                        alt="icon-contact"
                      />
                    </div>
                    <p className="name-menu">ติดต่อ</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav >
  );
};

export default Header;


