import React from "react";
import { setLocalStorage } from "../../utils/helper";
import "./Welcome.css";

const Welcome: React.FC = () => {
  const setVisitedWelcomePage = () => {
    setLocalStorage("hasVisitedWelcomePage", "true");
  };
  return (
    <>
      <section className="block-first-open-login">
        <div className="lg:container lg:mx-auto">
          <div className="image-logo">
            <img
              className="logo-login"
              src="/assets/logo/logo-brand-vertical.png"
              alt="logo-brand-vertical"
            />
          </div>
          <p className="title-login-top text-center">
            Sunt architecto pariatur repellat ullam ipsum et voluptas.
          </p>
          <div className="image-banner-content">
            <img
              className="banner-login"
              src="/assets/images/banner-login-01.png"
              alt="banner-login-01"
            />
          </div>
          <p className="title-login-bottom text-center">
            Non qui aliquid cupiditate quibusdam vel aperiam suscipit cum. Esse
            quos quo. Sequi ut suscipit sit.{" "}
          </p>
          <div className="btn-link-group">
            <a
              className="link btn-link-login"
              href="/login"
              onClick={setVisitedWelcomePage}
            >
              เข้าสู่ระบบ
            </a>
            <a
              className="link link-hover link-skip"
              href="/"
              onClick={setVisitedWelcomePage}
            >
              ข้าม
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
