import React, { useState } from 'react'
import "./notFound.css";

const NotFound: React.FC = () => {

  return (
    <section className="not-found-wrapper bg-white">
      <div className="block-not-found">
        <div className="block-image">
          <img
            className="image-error"
            src="/assets/images/image-error.png"
            alt="image-error"
          />
        </div>
        <h2 className="error-title">
          <span>
            ขออภัย &nbsp;
          </span>
          ไม่พบหน้าเว็บไซต์ที่คุณต้องการ
        </h2>
        <button className="btn btn-back-home">
          กลับสู่หน้าหลัก
          <img
            className="icon-arrow-right"
            src="/assets/icons/icon-arrow-right.svg"
            alt="icon-arrow-right"
          />
        </button>
      </div>
    </section>
  )
}

export default NotFound;