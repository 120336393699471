import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiServices } from "../../services/endpoints/ApiEndpoint";
import { UserTypeId } from "../../utils/enums";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../utils/helper";
import { AdditionalTypeString, payloadRegister } from "../../utils/models";
import "./register.css";

const Register: React.FC = () => {
  const { RegisterAsync } = useApiServices()
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<payloadRegister & AdditionalTypeString>();
  const navigate = useNavigate();
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(false);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] =
    useState<boolean>(false);
  const phoneNumber: string = getLocalStorage("phoneNumber") ?? "-";
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancel = () => {
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    removeLocalStorage("expireTime");
    removeLocalStorage("phoneNumber");
    navigate("/login");
  };

  const onSubmitRegister = async (
    data: payloadRegister & AdditionalTypeString
  ) => {
    try {
      setIsLoading(true);
      delete data.confirmPassword;

      const payload: payloadRegister = {
        ...data,
        userTypeId: UserTypeId.Customer,
      };
      const { result } = await RegisterAsync(payload);
      setIsLoading(false);

      if (result.isSuccess) {
        removeLocalStorage("phoneNumber");
        setLocalStorage("accessToken", result?.accessToken ?? null);
        setLocalStorage("refreshToken", result?.refreshToken ?? null);
        setLocalStorage("expireTime", result?.expireTime ?? null);
        navigate("/");
      } else {
        toast.error(`${result?.message ?? "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"}`, {
          position: "bottom-left",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("register", error);
      toast.error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง", {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    if (phoneNumber) {
      setValue("phone", phoneNumber);
    }
  }, [phoneNumber]);

  return (
    <>
      <section className="block-register-wrapper">
        <img
          className="bg-form"
          src="/assets/images/bg-city-01.png"
          alt="bg-city-01"
        />
        <div className="block-register">
          <div className="image-logo">
            <img
              className="logo-register"
              src="/assets/logo/logo-brand-vertical.png"
              alt="logo-brand-vertical"
            />
          </div>
          <h1 className="title">กรอกข้อมูลส่วนตัว</h1>
          <form onSubmit={handleSubmit(onSubmitRegister)}>
            <div className="group-form">
              <div className="group-form-top-left">
                <label className="form-control w-full form-register-label input-item">
                  <div className="label">
                    <span className="label-text">ชื่อ</span>
                  </div>
                  <input
                    {...register("firstName", {
                      required: "กรุณากรอกชื่อ",
                    })}
                    type="text"
                    placeholder="กรอกชื่อ"
                    className="input input-bordered w-full"
                  />
                  {errors?.firstName && (
                    <span className="text-error text-sm mt-2">
                      {errors?.firstName?.message}
                    </span>
                  )}
                </label>
                <label className="form-control w-full form-register-label input-item">
                  <div className="label">
                    <span className="label-text">นามสกุล</span>
                  </div>
                  <input
                    {...register("lastName", { required: "กรุณากรอกนามสกุล" })}
                    type="text"
                    placeholder="กรอกนามสกุล"
                    className="input input-bordered w-full"
                  />
                  {errors?.lastName && (
                    <span className="text-error text-sm mt-2">
                      {errors?.lastName?.message}
                    </span>
                  )}
                </label>
                <label className="form-control w-full form-register-label input-item">
                  <div className="label">
                    <span className="label-text">เบอร์โทรศัพท์</span>
                  </div>
                  <input
                    {...register("phone", {
                      required: "กรุณากรอกเบอร์โทรศัพท์",
                      pattern: /[0-9]{10}/,
                      maxLength: 10,
                    })}
                    type="text"
                    placeholder="เบอร์โทรศัพท์"
                    className="input input-bordered w-full"
                    value={phoneNumber}
                    disabled
                  />
                  {errors?.phone && (
                    <span className="text-error text-sm mt-2">
                      {errors?.phone?.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="group-form-bottm-right">
                <label className="form-control w-full form-register-label input-item">
                  <div className="label">
                    <span className="label-text">อีเมล</span>
                  </div>
                  <input
                    {...register("email", {
                      required: "กรุณากรอกอีเมล",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message:
                          "รูปแบบอีเมลไม่ถูกต้อง กรุณาตรวจสอบอีเมลของคุณอีกครั้ง",
                      },
                    })}
                    type="email"
                    placeholder="กรอกอีเมล"
                    className="input input-bordered w-full"
                  />
                  {errors?.email && (
                    <span className="text-error text-sm mt-2">
                      {errors?.email?.message}
                    </span>
                  )}
                </label>
                <label className="form-control w-full form-register-label">
                  <div className="label">
                    <span className="label-text">รหัสผ่าน</span>
                  </div>
                </label>
                <label className="input input-bordered form-register-label form-register input-item">
                  <input
                    {...register("password", { required: "กรุณากรอกรหัสผ่าน" })}
                    className="grow"
                    type={!hiddenPassword ? "password" : "text"}
                    placeholder="กรอกรหัสผ่าน"
                    autoComplete="current-password"
                  />
                  {!hiddenPassword ? (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent hidden-password"
                      onClick={(e) => setHiddenPassword(true)}
                    >
                      <img
                        className="icon-eye-hidden"
                        src="/assets/icons/icon-eye-hidden.svg"
                        alt="icon-eye-hidden"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent view-password"
                      onClick={(e) => setHiddenPassword(false)}
                    >
                      <img
                        className="icon-eye-view"
                        src="/assets/icons/icon-eye-view.svg"
                        alt="icon-eye-view"
                      />
                    </button>
                  )}
                </label>
                {errors?.password && (
                  <span className="text-error text-sm mt-2">
                    {errors?.password?.message}
                  </span>
                )}
                <label className="form-control w-full form-register-label">
                  <div className="label">
                    <span className="label-text">ยืนยันรหัสผ่าน</span>
                  </div>
                </label>
                <label className="input input-bordered form-register-label form-register input-item">
                  <input
                    {...register("confirmPassword", {
                      required: "กรุณากรอกรหัสผ่าน",
                      validate: (val: string) => {
                        if (watch("password") !== val) {
                          return "รหัสผ่านของคุณไม่ตรงกัน กรุณาป้อนรหัสผ่านใหม่อีกครั้ง";
                        }
                      },
                    })}
                    className="grow"
                    type={!hiddenConfirmPassword ? "password" : "text"}
                    placeholder="กรอกรหัสผ่าน"
                    autoComplete="current-password"
                  />
                  {!hiddenConfirmPassword ? (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent hidden-password"
                      onClick={(e) => setHiddenConfirmPassword(true)}
                    >
                      <img
                        className="icon-eye-hidden"
                        src="/assets/icons/icon-eye-hidden.svg"
                        alt="icon-eye-hidden"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent view-password"
                      onClick={(e) => setHiddenConfirmPassword(false)}
                    >
                      <img
                        className="icon-eye-view"
                        src="/assets/icons/icon-eye-view.svg"
                        alt="icon-eye-view"
                      />
                    </button>
                  )}
                </label>
                {errors?.confirmPassword && (
                  <span className="text-error text-sm mt-2">
                    {errors?.confirmPassword?.message}
                  </span>
                )}
              </div>
            </div>
            <button type="button" className="btn btn-cancel" onClick={onCancel}>
              ยกเลิก
            </button>
            <button
              type="submit"
              className="btn btn-confirm disabled:bg-slate-500"
              disabled={isLoading}
            >
              ยืนยัน
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Register;
