import React from 'react'
import "./contact.css";
import GoogleMapReact from 'google-map-react';

const Contact: React.FC = () => {
  return (
    <section className="contact-wrapper bg-white">
      <div className="block-contact lg:container lg:mx-auto">
        <div className="block-about-me">
          <div className="title mb-2">
            เกี่ยวกับเรา
          </div>
          <p className="details">
            เพื่อนรับเหมา บริการรับจองคอนกรีตทั่วประเทศ เรามีแพลนพร้อมให้บริการมากกว่า 800 แห่ง
            ช่วยให้คุณหาราคาได้ดีที่สุด ไวที่สุด และง่ายที่สุด
          </p>
        </div>

        <div className="block-address">
          <div className="title mb-2">
            ที่อยู่
          </div>
          <p className="details">
            บริษัท คอนกรีต เดลิเวอรี่ จำกัด (สำนักงานใหญ่)
            37/1 หมู่ที่ 2 ตำบลหนองปลาไหล อำเภอบางละมุง จังหวัดชลบุรี
            เลขประจำตัวผู้เสียภาษี 0205562008783
          </p>
        </div>

        <div className="block-contact">
          <div className="title mb-2">
            ติดต่อ
          </div>
          <div className="contact-list">
            <p className="list-items">
              <img src="/assets/icons/icon-phone-gray.svg" alt="icon-phone-gray" className="icon" />
              092-912-9479
            </p>
            <p className="list-items">
              <img src="/assets/icons/icon-line-official.svg" alt="icon-line-official" className="icon" />
              @concrete_d
            </p>
          </div>
        </div>

        <div className="block-contact">
          <div className="title mb-2">
            แผนที่
          </div>
          <div className="google-Map-wrapper">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "" }}
              defaultCenter={{ lat: 13.6970213, lng: 100.6083957 }}
              defaultZoom={15}
            >
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact;