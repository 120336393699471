import React from 'react';
import logo from './logo.svg';
import "./App.css";
import { accessRoute, hasVisitedWelcomePage } from "./utils/helper";
import Footer from "./components/footer/Footer";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import Welcome from "./pages/Welcom/Welcome";
import Login from "./pages/Login/Login";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import FormPhoneOTP from "./pages/FormPhoneOTP/FormPhoneOTP";
import Register from "./pages/Register/Register";
import MapSelector from "./pages/MapSelector/MapSelector";
import Header from "./components/header/Header";
import Profile from "./pages/Profile/Profile";
import ViewDetailProduct from './pages/ViewDetailProduct/ViewDetailProduct';
import ProductListCart from './pages/ProductListCart/ProductListCart';
import Payment from './pages/Payment/Payment';
import Order from './pages/Order/Order';
import OrderDetail from './pages/OrderDetail/OrderDetail';
import PaymentDetail from './pages/PaymentDetail/PaymentDetail';
import FooterDesktop from "./components/footer-desktop/FooterDesktop";
import LoadingScreen from './components/loading/LoadingScreen';
import useDeviceType from './utils/useDeviceType';
import Contact from './pages/Contact/Contact';
import RecipientDetails from './pages/RecipientDetails/RecipientDetails';
import NotFound from './pages/NotFound/NotFound';
import Error from './pages/Error/Error';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions/TermsConditions';
import ProfileEdit from './pages/ProfileEdit/ProfileEdit';
import TaxInvoiceInformationEdit from './pages/TaxInvoiceInformationEdit/TaxInvoiceInformationEdit';

function WelcomeWithVisitCheck() {
  return hasVisitedWelcomePage() ? <Home /> : <Navigate to="/welcome" />;
}
function AccessRoute({ comp }: { comp: JSX.Element }) {
  return accessRoute() ? <Navigate to="/" /> : comp;
}

function App() {
  const location = useLocation();
  const hidePaths = ['/login', "/register-otp", "/reset-password", "/reset-password-otp", "/register", "/welcome"];
  const isMobile = useDeviceType();
  const hideHeader = () => {
    if (isMobile) {
      console.log('hidePaths.includes(location.pathname)', hidePaths.includes(location.pathname));

      return hidePaths.includes(location.pathname)
    } else {
      return false
    }
  }
  return (

    <div className="flex-wrapper">
      <LoadingScreen />
      {!hideHeader() && <Header pathname={location.pathname} />}
      <main className={`content${hideHeader() ? '-nopadding' : ''}`}>
        <Routes>
          <Route path="/" element={<WelcomeWithVisitCheck />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/profile/edit" element={<ProfileEdit />} /> */}
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/login" element={<AccessRoute comp={<Login />} />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register-otp" element={<AccessRoute comp={<FormPhoneOTP page="register" />} />} />
          <Route path="/reset-password-otp" element={<FormPhoneOTP page="reset-password" />} />
          <Route path="/register" element={<AccessRoute comp={<Register />} />} />
          <Route path="/location" element={<MapSelector />} />
          <Route path='/product/:id' element={<ViewDetailProduct />} />
          <Route path='/cart' element={<ProductListCart />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment-detail/:id' element={<PaymentDetail />} />
          <Route path='/order' element={<Order />} />
          <Route path='/order/:id' element={<OrderDetail />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/recipient-details' element={<RecipientDetails />} />
          <Route path='/not-found' element={<NotFound />} />
          <Route path='/error' element={<Error />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-conditions' element={<TermsConditions />} />
          <Route path='/profile/edit' element={<ProfileEdit />} />
          <Route path='/profile/edit' element={<ProfileEdit />} />
          <Route path='/taxinvoice-information/edit' element={<TaxInvoiceInformationEdit />} />
        </Routes>
      </main>
      <FooterDesktop pathname={location.pathname} />
      {!hideHeader() && isMobile && <Footer />}
    </div>
  );
}

export default App;
