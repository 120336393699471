import React from 'react'
import './OrderDetail.css';
import GoogleMapReact from 'google-map-react';

const OrderDetail: React.FC = () => {

  return (
    <section className="order-detail-wrapper bg-white">
      <div className="block-order-detail lg:container lg:mx-auto">
        <h2 className="title">
          รายละเอียด
        </h2>

        <div className="block-steps">
          <ul className="steps">
            <li className="step step-primary" data-content="✓">รอชำระ</li>
            <li className="step step-primary" data-content="✓">รอจัดส่ง</li>
            <li className="step" data-content="●">กำลังจัดส่ง</li>
            <li className="step" data-content="●">สำเร็จ</li>
          </ul>
        </div>

        <div className="block-recipient-details">
          <div className="recipient-title">
            <div className="line"></div>
            <h3 className="text">รายละเอียดผู้รับ</h3>
            <div className="line"></div>
          </div>
          <p className="recipient-details">
            <span className="px-[0.5rem]">
              ชื่อผู้รับ
            </span>
            <span className="font-normal px-[0.5rem]">
              มานี มีใจ
            </span>
          </p>
          <p className="recipient-details">
            <span className="px-[0.5rem]">
              เบอร์ติดต่อ
            </span>
            <span className="font-normal px-[0.5rem]">
              064-8765432
            </span>
          </p>
        </div>

        <div className="block-ordered-items">
          <div className="ordered-items-title">
            <div className="line"></div>
            <h3 className="text">รายการที่สั่ง</h3>
            <div className="line"></div>
          </div>
          <p className="ordered-items">
            <span className="px-[0.5rem]">
              สินค้า
            </span>
            <span className="font-normal px-[0.5rem]">
              เหล็ก WF-BEAM  300x150x6.5x9มม. 9 เมตร SS400
            </span>
          </p>
          <p className="ordered-items">
            <span className="px-[0.5rem]">
              จำนวน
            </span>
            <span className="font-normal px-[0.5rem]">
              10 เส้น
            </span>
          </p>
        </div>

        <div style={{ height: '620px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={{ lat: 13.6970213, lng: 100.6083957 }}
            defaultZoom={15}
          >
          </GoogleMapReact>
        </div>

        <div className="block-total-price">
          <p className="items">
            <span className="px-[0.5rem]">ราคารวม</span>
            <span className="px-[0.5rem]">3,300 บาท</span>
          </p>
        </div>
        <div className="block-btn">
          <button className="btn btn-back">ย้อนกลับ</button>
        </div>
      </div>
    </section>
  )
}

export default OrderDetail;
