import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiServices } from "../../services/endpoints/ApiEndpoint";
import { SmsOtpType } from "../../utils/enums";
import { setLocalStorage } from "../../utils/helper";
import {
  payloadRequestOtp,
  payloadVerifyOtp,
  resultResponseRequestOtp,
} from "../../utils/models";
import "./styleFormOTP.css";
import "./styleFormPhone.css";

interface PhoneOtpProps {
  page: string;
}

const PhoneOTP: React.FC<PhoneOtpProps> = ({ page = "register" }) => {
  const {
    RequestOtpAsync,
    VerifyOtpAsync,
  } = useApiServices();
  const {
    handleSubmit: handleSubmitOTP,
    register,
    watch,
    formState: { errors },
  } = useForm<{ phone: string }>();
  const phoneWatch: string = watch("phone");
  const { handleSubmit: handleSubmitVerifyOTP } = useForm<{ pin: string }>();

  const length = 6;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openLoginPage, setOpenLoginPage] = useState<boolean>(false);
  const [resultRequestOTP, setResultRequestOTP] =
    useState<resultResponseRequestOtp>();
  const [countdownNewOTP, setCountdownNewOTP] = useState<number>(60);
  const [OTP, setOTP] = useState<string[]>(Array(length).fill(""));
  const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));

  const resetOTPRequestTimer = () => {
    requestOtp({ phone: phoneWatch });
    setCountdownNewOTP(60);
    setOpenLoginPage(true);
  };

  const requestOtp = async (data: { phone: string }) => {
    try {
      setIsLoading(true);
      const payload: payloadRequestOtp = {
        phone: data.phone,
        smsOtpType:
          page === "register" ? SmsOtpType.Register : SmsOtpType.ResetPassword,
      };
      const { result } = await RequestOtpAsync(payload);
      setIsLoading(false);

      if (result.isSuccess) {
        setOpenLoginPage(true);
        setResultRequestOTP(result);
      } else {
        toast.error(`${result?.message ?? "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"}`, {
          position: "bottom-left",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log("requestOtp", error);
      toast.error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง", {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const verifyOtp = async () => {
    try {
      const payload: payloadVerifyOtp = {
        token: resultRequestOTP?.token,
        pin: OTP.join(""),
      };

      const { result } = await VerifyOtpAsync(payload);

      if (result.isSuccess) {
        setLocalStorage("phoneNumber", phoneWatch ?? null);
        setLocalStorage("accessToken", result?.accessToken ?? null);
        setLocalStorage("refreshToken", result?.refreshToken ?? null);
        setLocalStorage("expireTime", result?.expireTime ?? null);

        if (page === "register") {
          navigate("/register");
        } else {
          navigate("/reset-password");
        }
      } else {
        toast.error(`${result?.message ?? "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"}`, {
          position: "bottom-left",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง", {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  const handleTextChange = (input: string, index: number) => {
    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }
  };

  useEffect(() => {
    let intervalTimeOTP: NodeJS.Timeout;

    if (openLoginPage) {
      intervalTimeOTP = setInterval(() => {
        setCountdownNewOTP((currentTimeNewOTP) => {
          if (currentTimeNewOTP === 0) {
            return 0;
          } else {
            return currentTimeNewOTP - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(intervalTimeOTP);
  }, [openLoginPage]);

  return (
    <>
      {!openLoginPage ? (
        <section className="block-form-phone-wrapper">
          <div className="block-left">
            <img
              className="banner-form-phone"
              src="/assets/images/banner-form-phone-otp-01.png"
              alt="banner-form-phone-otp-01"
            />
          </div>
          <div className="block-right">
            <div className="image-logo">
              <img
                className="logo-form-phone"
                src="/assets/logo/logo-brand-vertical.png"
                alt="logo-brand-vertical"
              />
            </div>
            <h1 className="title">
              {page === "register"
                ? "ยืนยันเบอร์โทรศัพท์ส่วนตัว"
                : "ลืมรหัสผ่าน"}
            </h1>
            <h2 className="sub-title">
              กรอกข้อมูลส่วนตัวเพื่อยืนยันตัวตนของคุณ
            </h2>
            <form onSubmit={handleSubmitOTP(requestOtp)}>
              <label className="label-input-phone form-control">
                <div className="label">
                  <span className="label-text">เบอร์โทรศัพท์</span>
                </div>
                <input
                  {...register("phone", {
                    required: true,
                    pattern: /[0-9]{10}/,
                    maxLength: 10,
                  })}
                  placeholder="กรอกเบอร์โทรศัพท์"
                  className="input input-bordered"
                />
                {errors?.phone && (
                  <span className="text-error text-sm mt-2">
                    กรุณากรอกเบอร์โทรศัพท์
                  </span>
                )}
              </label>
              <button
                type="submit"
                className="btn btn-form-phone disabled:!bg-slate-500"
                disabled={isLoading}
              >
                ส่ง
              </button>
            </form>
          </div>
        </section>
      ) : (
        <section className="block-phone-otp-wrapper">
          <img
            className="bg-form"
            src="/assets/images/bg-city-01.png"
            alt="bg-city-01"
          />
          <div className="block-form-otp">
            <div className="image-logo">
              <img
                className="logo-phone-otp"
                src="/assets/logo/logo-brand-vertical.png"
                alt="logo-brand-vertical"
              />
            </div>
            <h1 className="title">ยืนยันด้วยรหัส OTP</h1>
            <h2 className="phone-otp-send">
              กรอกรหัส OTP ที่ส่งไปที่ ******{phoneWatch.slice(-4)}
            </h2>
            <h3 className="reference-number">
              เลขที่อ้างอิง : {resultRequestOTP?.ref}
            </h3>
            <form onSubmit={handleSubmitVerifyOTP(verifyOtp)}>
              <label className="label-input-phone">
                {Array.from({ length }, (_, index) => (
                  <input
                    key={index}
                    ref={(ref) =>
                      (inputRef.current[index] = ref as HTMLInputElement)
                    }
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    value={OTP[index]}
                    required
                    type="text"
                    maxLength={1}
                    className="input input-item"
                  />
                ))}
              </label>
              <button
                type="button"
                className="btn btn-ghost btn-new-otp"
                disabled={countdownNewOTP !== 0}
                onClick={resetOTPRequestTimer}
              >
                <span>
                  ขอรหัส OTP ใหม่
                  {countdownNewOTP !== 0 && " ( " + countdownNewOTP + " )"}
                </span>
              </button>
              <button type="submit" className="btn btn-send-otp">
                ยืนยัน
              </button>
            </form>
          </div>
        </section>
      )}
    </>
  );
};

export default PhoneOTP;
