import React from 'react'
import "./termsConditions.css";

const TermsConditions: React.FC = () => {
  return (
    <section className="terms-conditions-wrapper bg-white">
      <div className="block-terms-conditions lg:container lg:mx-auto">
        <h2 className="title-terms-conditions">นโยบายความเป็นส่วนตัว</h2>
        <div className="px-[1rem] py-[2rem] max-[430px]:py-[1.5rem]">
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              ข้อกำหนดและเงื่อนไขสำหรับเว็บไซต์ "เพื่อนรับเหมา"
            </h3>
            <p className="policy-content-details">
              ยินดีต้อนรับสู่เว็บไซต์ "เพื่อนรับเหมา" ซึ่งเป็นเว็บไซต์ของบริษัท คอนกรีต เดลิเวอรี่ จำกัด ข้อกำหนดและเงื่อนไขเหล่านี้กำหนดข้อตกลงในการใช้งานเว็บไซต์
              และบริการต่างๆ ที่เราเสนอ. การเข้าถึงและใช้งานเว็บไซต์นี้ถือว่าคุณยอมรับและตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขเหล่านี้.
            </p>
          </div>
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              การใช้งานเว็บไซต์
            </h3>
            <ul className="list-decimal pl-[2rem]">
              <li>
                <p>
                  <span>
                    การเข้าถึงและใช้งาน :&nbsp;
                  </span>
                  ท่านสามารถเข้าถึงและใช้งานเว็บไซต์นี้ตามวัตถุประสงค์ส่วนตัวและไม่ใช่เพื่อการค้า.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    การห้ามไม่ให้ทำซ้ำ :&nbsp;
                  </span>
                  ห้ามทำซ้ำ คัดลอก ขาย หรือใช้ประโยชน์จากเว็บไซต์นี้และเนื้อหาใดๆที่เกี่ยวข้องเพื่อวัตถุประสงค์ทางการค้า
                  โดยไม่ได้รับอนุญาตจากเรา.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    การปฏิบัติที่ถูกต้อง :&nbsp;
                  </span>
                  ท่านตกลงที่จะไม่ใช้เว็บไซต์นี้เพื่อวัตถุประสงค์ที่ผิดกฎหมายหรือห้ามไว้ตามข้อกำหนดและเงื่อนไขเหล่านี้.
                </p>
              </li>
            </ul>
          </div>
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              สิทธิ์ในทรัพย์สินทางปัญญา
            </h3>
            <p className="policy-content-details">
              เนื้อหาทั้งหมดบนเว็บไซต์นี้ เช่น ข้อความ กราฟิก โลโก้ ไอคอนปุ่ม ภาพถ่าย และซอฟต์แวร์ เป็นทรัพย์สินของบริษัท คอนกรีต เดลิเวอรี่ จำกัด หรือเป็นทรัพย์สินที่บริษัทมีสิทธิ์ใช้งานและได้รับการปกป้องภายใต้กฎหมายลิขสิทธิ์และกฎหมายทรัพย์สินทางปัญญาอื่นๆ.
            </p>
          </div>
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              การยกเว้นความรับผิด
            </h3>
            <p className="policy-content-details">
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด ไม่รับประกันว่าเว็บไซต์หรือเนื้อหาใดๆ ที่ให้บริการจะไม่มีข้อผิดพลาดหรือการหยุดชะงัก นอกจากนี้ <br />
              เราไม่รับประกันความถูกต้อง, ความครบถ้วน, หรือความเป็นปัจจุบันของข้อมูลที่มีให้บนเว็บไซต์นี้ ทุกการใช้งานเว็บไซต์และเนื้อหาที่ให้บริการจะเป็นความเสี่ยงของผู้ใช้เอง
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด หรือตัวแทนใดๆ ของเราจะไม่รับผิดชอบต่อความเสียหายใดๆ ไม่ว่าจะเป็นความเสียหายโดยตรง, โดยอ้อม, พิเศษ, โทษทัณฑ์, หรือผลเสียอื่นๆ ที่เกิดจากการใช้งานหรือความไม่สามารถในการใช้งานเว็บไซต์นี้หรือจากข้อมูลหรือบริการใดๆ ที่ได้รับผ่านเว็บไซต์นี้ ไม่ว่าจะอ้างอิงจากข้อสัญญา, การละเมิด, ความรับผิดทางแพ่ง หรืออื่นๆ แม้ว่าบริษัท คอนกรีต เดลิเวอรี่ จำกัด จะถูกแจ้งให้ทราบถึงความเป็นไปได้ของความเสียหายดังกล่าวก็ตาม
            </p>
          </div>
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              การเชื่อมโยงกับเว็บไซต์อื่น
            </h3>
            <p className="policy-content-details">
              เว็บไซต์ "เพื่อนรับเหมา" อาจมีลิงก์ไปยังเว็บไซต์อื่นที่ไม่ได้ดำเนินการโดยบริษัท คอนกรีต เดลิเวอรี่ จำกัด เราไม่มีความรับผิดชอบหรือความเสี่ยงใดๆ ต่อเนื้อหาหรือการปฏิบัติตามนโยบายความเป็นส่วนตัวของเว็บไซต์ดังกล่าว
            </p>
          </div>
          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              การเปลี่ยนแปลงข้อกำหนดและเงื่อนไข
            </h3>
            <p className="policy-content-details">
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด ขอสงวนสิทธิ์ในการเปลี่ยนแปลง, ปรับปรุง, เพิ่มเติม, หรือลบบางส่วนของข้อกำหนดและเงื่อนไขเหล่านี้ได้ตลอดเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า การเข้าถึงและการใช้งานเว็บไซต์ต่อไปของท่านหลังจากการเปลี่ยนแปลงดังกล่าวจะถือว่าคุณยอมรับและตกลงที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขที่ได้รับการแก้ไข
              <br />
              หากท่านมีคำถามเกี่ยวกับข้อกำหนดและเงื่อนไขนี้, กรุณาติดต่อเราที่โทรศัพท์หมายเลข 0929129479 เรายินดีที่จะช่วยเหลือและตอบคำถามหรือข้อกังวลใดๆ ที่ท่านอาจมีเกี่ยวกับข้อกำหนดและเงื่อนไขของเรา.
              การติดต่อเราแสดงถึงความยินดีของเราที่จะให้บริการและสนับสนุนท่านในการใช้งานเว็บไซต์ "เพื่อนรับเหมา" อย่างมีความสุขและไร้ปัญหา ขอบคุณที่เลือกใช้บริการของเรา
            </p>
          </div>

          <div className="terms-conditions-content">
            <h3 className="policy-content-title">
              การเปลี่ยนแปลงข้อกำหนดและเงื่อนไข
            </h3>
            <p className="policy-content-details">
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด ขอสงวนสิทธิ์ในการเปลี่ยนแปลง, ปรับปรุง, เพิ่มเติม, หรือลบบางส่วนของข้อกำหนดและเงื่อนไขเหล่านี้ได้ตลอดเวลา
              โดยไม่ต้องแจ้งให้ทราบล่วงหน้า การเข้าถึงและการใช้งานเว็บไซต์ต่อไปของท่านหลังจากการเปลี่ยนแปลงดังกล่าวจะถือว่าคุณยอมรับและตกลง
              ที่จะปฏิบัติตามข้อกำหนดและเงื่อนไขที่ได้รับการแก้ไข
              <br /><br />
              หากท่านมีคำถามเกี่ยวกับข้อกำหนดและเงื่อนไขนี้, กรุณาติดต่อเราที่โทรศัพท์หมายเลข 0929129479 เรายินดีที่จะช่วยเหลือและตอบคำถามหรือข้อกังวลใดๆ ที่ท่านอาจมีเกี่ยวกับข้อกำหนดและเงื่อนไขของเรา.
              <br /><br />
              การติดต่อเราแสดงถึงความยินดีของเราที่จะให้บริการและสนับสนุนท่านในการใช้งานเว็บไซต์ "เพื่อนรับเหมา" อย่างมีความสุขและไร้ปัญหา ขอบคุณที่เลือกใช้บริการของเรา
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsConditions;