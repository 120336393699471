import React, { useState } from 'react';
import './Payment.css';

const Payment: React.FC = () => {

  const [addCardCredit, setAddcardCredit] = useState<boolean>(false);

  const onAddCardCredit = () => {
    if (addCardCredit === false) {
      setAddcardCredit(true);
    } else {
      setAddcardCredit(false);
    }
  };

  console.log('addCardCredit', addCardCredit)
  return (
    <section className="payment-wrapper">
      <div className="block-choose-payment lg:container lg:mx-auto">
        <h2 className="payment-title">
          เลือกช่องทางการชำระเงิน
        </h2>
        <div className="payment-items">
          <h3 className="items-title">
            วิธีการชำระเงิน
          </h3>
          <div className="collapse collapse-arrow">
            <input type="checkbox" className="peer" />
            <div className="collapse-title text-xl font-medium w-full">
              <div className="collapse-title-left">
                <p className="collapse-title-name">
                  <img className="icon-credit-orange" src="/assets/icons/icon-credit-orange.svg" alt="icon-credit-orange" />
                  บัตรเครดิต/เดบิต
                </p>
                <p className="sub-title-name">
                  <img className="current-icon-card" src="/assets/icons/icon-master-card.svg" alt="icon-master-card" />
                  <span className="current-card-name">บัตรเครดิตในเครือกรุงศรี</span>
                  <span className="current-card-number">*1895</span>
                </p>
              </div>
              <div className="collapse-title-right">
                <input type="radio" className="radio" />
                <div className="box-blank"></div>
              </div>
            </div>
            <div className="collapse-content">
              {/* card 1 */}
              <div className="content-items">
                <div className="content-items-left">
                  <img className="icon-master-card" src="/assets/icons/icon-master-card.svg" alt="icon-master-card" />
                  <p className="items-left-name">
                    บัตรเครดิตในเครือกรุงศรี
                  </p>
                </div>

                <span className="content-items-right">
                  <p className="card-number">
                    *1895
                  </p>
                  <img className="icon-check" src="/assets/icons/icon-check.svg" alt="icon-check" />
                </span>
              </div>
              {/* card 2 */}
              <div className="content-items">
                <div className="content-items-left">
                  <img className="icon-master-card" src="/assets/icons/icon-master-card.svg" alt="icon-master-card" />
                  <p className="items-left-name">
                    บัตรเครดิต KTC
                  </p>
                </div>

                <span className="content-items-right">
                  <p className="card-number">
                    *4266
                  </p>
                  <img className="icon-check" src="/assets/icons/icon-check.svg" alt="icon-check" />
                </span>
              </div>

              <button className="content-items add-card-items" onClick={onAddCardCredit}>
                <p className="btn btn-add-card-items">
                  <img className="icon-plus-gray" src="/assets/icons/icon-plus-gray.svg" alt="icon-plus-gray" />
                  <span className="btn-name">
                    เพิ่มบัตรเครดิต/เดบิต
                  </span>
                </p>
              </button>
              {addCardCredit &&
                <div className="content-items block-form-add-card-credit">
                  <label className="input input-bordered flex items-center gap-2 add-card-number">
                    <div className="text-label">หมายเลขบัตร :</div>
                    <input type="text" className="grow" placeholder="" />
                  </label>
                  <span className="flex expire-cvv">
                    <label className="input input-bordered flex items-center gap-2 mr-3 expire">
                      <div className="text-label">วันที่หมดอายุ :</div>
                      <input type="text" className="grow" placeholder="" />
                    </label>
                    <label className="input input-bordered flex items-center gap-2 cvv">
                      <div className="text-label">CVV :</div>
                      <input type="text" className="grow" placeholder="" />
                      <img className="icon-credit-orange" src="/assets/icons/icon_information-outline.svg" alt="icon_information-outline" />
                    </label>
                  </span>
                  <div className="add-card-terms-and-conditions">
                    <input type="checkbox" className="checkbox [--chkbg:#FD6B35]" />
                    <p className="terms-and-conditions">ข้าพเจ้ายอมรับใน ข้อกำหนดและเงื่อนไขการให้บริการไลน์เพย์ สำหรับท่านที่มิใช่สมาชิกและใช้จ่ายผ่านบัตรที่ใช้ชำระเงิน, นโยบายความเป็นส่วนตัว</p>
                  </div>
                  <div className="flex justify-center p-2">
                    <button className="btn btn-add-card">เพิ่ม</button>
                  </div>
                </div>
              }
            </div>
          </div>

          <div className="qr-promptpay">
            <div className="qr-promptpay-left">
              <img className="icon-qr-promptpay" src="/assets/icons/icon-qr-promptpay.svg" alt="icon-qr-promptpay" />
              <p className="qr-promptpay-name">QR พร้อมเพย์</p>
            </div>
            <div className="qr-promptpay-right">
              <input type="radio" className="radio" defaultChecked />
            </div>
          </div>

          <div className="collapse collapse-arrow">
            <input type="checkbox" name="my-accordion-2" />
            <div className="collapse-title text-xl font-medium">
              <div className="collapse-title-left">
                <p className="collapse-title-name">
                  <img className="icon-credit-orange" src="/assets/icons/icon-credit-orange.svg" alt="icon-credit-orange" />
                  โอนเงินผ่านแอปธนาคาร
                </p>
              </div>
              <div className="collapse-title-right">
                <input type="radio" className="radio" />
              </div>
            </div>
            <div className="collapse-content">
              {/* card 1 */}
              <div className="content-items">
                <div className="content-items-left">
                  <img className="icon-master-card" src="/assets/icons/icon-master-card.svg" alt="icon-master-card" />
                  <p className="items-left-name">
                    K Bank
                  </p>
                </div>

                <span className="content-items-right">
                  <img className="icon-check" src="/assets/icons/icon-check.svg" alt="icon-check" />
                </span>
              </div>
              {/* card 2 */}
              {/* <div className="content-items">
                <div className="content-items-left">
                  <img className="icon-master-card" src="/assets/icons/icon-master-card.svg" alt="icon-master-card" />
                  <p className="items-left-name">
                    บัตรเครดิต KTC
                  </p>
                </div>

                <span className="content-items-right">
                  <p className="card-number">
                    *4266
                  </p>
                  <input type="radio" className="radio" />
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Payment;