import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useApiServices } from "../../services/endpoints/ApiEndpoint";
import { setLocalStorage } from "../../utils/helper";
import { payloadLogin } from "../../utils/models";
import "./login.css";

const Login: React.FC = () => {
  const { LoginAsync } = useApiServices()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<payloadLogin>();
  const navigate = useNavigate();
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitLogin = async (data: any) => {
    try {
      setIsLoading(true);
      const payload: payloadLogin = {
        phone: data.phone,
        password: data.password,
      };
      const { result } = await LoginAsync(payload);
      setIsLoading(false);
      if (result.isSuccess) {
        setLocalStorage("accessToken", result?.accessToken ?? null);
        setLocalStorage("refreshToken", result?.refreshToken ?? null);
        setLocalStorage("expireTime", result?.expireTime ?? null);

        navigate("/");
      } else {
        toast.error(`${result?.message ?? "เกิดข้อผิดพลาด โปรดลองอีกครั้ง"}`, {
          position: "bottom-left",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          theme: "colored",
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("login", error);
      toast.error("เกิดข้อผิดพลาด โปรดลองอีกครั้ง", {
        position: "bottom-left",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <section className="block-login">
        <div className="image-banner-content">
          <img
            className="banner-login"
            src="/assets/images/banner-login-03.png"
            alt="banner-login-02"
          />
        </div>
        <div className="block-login-form">
          <div className="image-logo">
            <img
              className="logo-login"
              src="/assets/logo/logo-brand-vertical.png"
              alt="logo-brand-vertical"
            />
          </div>
          <div className="image-banner-content">
            <img
              className="banner-login"
              src="/assets/images/banner-login-02.png"
              alt="banner-login-02"
            />
          </div>
          <h1 className="title">ยินดีต้อนรับกลับมา!</h1>
          <h2 className="sub-title">กรุณากรอกบัญชีของคุณที่นี่</h2>
          <form onSubmit={handleSubmit(onSubmitLogin)}>
            <div className="input-group-login">
              <div className="mb-6">
                <label className="input input-bordered form-user-password">
                  <img
                    className="icon-login"
                    src="/assets/icons/icon-phone.svg"
                    alt="logo"
                  />
                  <input
                    {...register("phone", {
                      required: "กรุณากรอกเบอร์โทรศัพท์",
                      pattern: /[0-9]{10}/,
                      maxLength: 10,
                    })}
                    type="text"
                    placeholder="เบอร์โทรศัพท์"
                    className="grow"
                  />
                </label>
                {errors?.phone && (
                  <span className="text-error text-sm">
                    {errors?.phone?.message}
                  </span>
                )}
              </div>
              <div className="mb-6">
                <label className="input input-bordered form-user-password">
                  <img
                    className="icon-login"
                    src="/assets/icons/icon-master-key.svg"
                    alt="logo"
                  />
                  <input
                    {...register("password", { required: "กรุณากรอกรหัสผ่าน" })}
                    className="grow"
                    type={!hiddenPassword ? "password" : "text"}
                    placeholder="รหัสผ่าน"
                    autoComplete="current-password"
                  />
                  {!hiddenPassword ? (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent hidden-password"
                      onClick={(e) => setHiddenPassword(true)}
                    >
                      <img
                        className="icon-eye-hidden"
                        src="/assets/icons/icon-eye-hidden.svg"
                        alt="icon-eye-hidden"
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-ghost hover:bg-transparent view-password"
                      onClick={(e) => setHiddenPassword(false)}
                    >
                      <img
                        className="icon-eye-view"
                        src="/assets/icons/icon-eye-view.svg"
                        alt="icon-eye-view"
                      />
                    </button>
                  )}
                </label>
                {errors?.password && (
                  <span className="text-error text-sm">
                    {errors?.password?.message}
                  </span>
                )}
              </div>
            </div>
            <div className="btn-login-group">
              <a
                className="link link-hover link-forgot-password"
                href="/reset-password-otp"
              >
                ลืมรหัสผ่าน ?
              </a>
              <button
                type="submit"
                className="btn btn-login"
                disabled={isLoading}
              >
                เข้าสู่ระบบ
              </button>
            </div>
            <p className="no-user">
              คุณยังไม่มีบัญชีใช่ไหม ?&nbsp;
              <a className="link link-no-user" href="/register-otp">
                ลงทะเบียน
              </a>
            </p>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
function setIsLoading(arg0: boolean) {
  throw new Error("Function not implemented.");
}
