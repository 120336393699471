import React from 'react'
import "./privacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <section className="privacy-policy-wrapper bg-white">
      <div className="block-privacy-policy lg:container lg:mx-auto">
        <h2 className="title-privacy-policy">นโยบายความเป็นส่วนตัว</h2>
        <div className="px-[1rem] py-[2rem] max-[430px]:py-[1.5rem]">
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              บทนำ
            </h3>
            <p className="policy-content-details">
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด (ต่อไปนี้จะเรียกว่า "บริษัท", "เรา", หรือ "ของเรา") ให้ความสำคัญกับการปกป้องข้อมูลส่วนบุคคลและความเป็นส่วนตัว
              ของผู้ใช้บริการเว็บไซต์ "เพื่อนรับเหมา" (ต่อไปนี้จะเรียกว่า "เว็บไซต์"). นโยบายความเป็นส่วนตัวนี้จัดทำขึ้นเพื่อให้ท่านทราบถึงแนวทางในการเก็บรวบรวม, ใช้, และเปิดเผยข้อมูลส่วนบุคคลที่ท่านได้ให้แก่เราผ่านเว็บไซต์.
            </p>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การเก็บรวบรวมข้อมูล
            </h3>
            <p className="policy-content-details !mb-0">
              เราอาจเก็บรวมข้อมูลส่วนบุคคลของท่านเมื่อท่านใช้งานเว็บไซต์, ซึ่งอาจรวมถึง :
            </p>
            <ul className="list-disc pl-[2rem]">
              <li>
                <p>
                  <span>
                    ข้อมูลติดต่อ&nbsp;
                  </span>
                  เช่น ชื่อ, ที่อยู่อีเมล, หมายเลขโทรศัพท์.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    ข้อมูลด้านเทคนิค&nbsp;
                  </span>
                  เช่น ที่อยู่ IP, ประเภทเบราว์เซอร์, รายละเอียดการเข้าชมเว็บไซต์
                </p>
              </li>
              <li>
                <p>
                  <span>
                    ข้อมูลการซื้อขาย &nbsp;
                  </span>
                  เช่น รายละเอียดการสั่งซื้อ, ประวัติการซื้อ.
                </p>
              </li>
            </ul>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การใช้ข้อมูล
            </h3>
            <p className="policy-content-details !mb-0">
              ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมอาจถูกใช้ในวัตถุประสงค์ดังต่อไปนี้ :
            </p>
            <ul className="list-disc pl-[2rem]">
              <li>
                <p>
                  เพื่อจัดการและปรับปรุงเว็บไซต์
                </p>
              </li>
              <li>
                <p>
                  เพื่อสื่อสารกับท่านเกี่ยวกับสินค้าหรือบริการของเรา
                </p>
              </li>
              <li>
                <p>
                  เพื่อดำเนินการตามคำสั่งซื้อที่ท่านได้ทำผ่านเว็บไซต์
                </p>
              </li>
              <li>
                <p>
                  เพื่อปฏิบัติตามข้อกำหนดทางกฎหมายและข้อบังคับ
                </p>
              </li>
            </ul>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การเปิดเผยข้อมูล
            </h3>
            <p className="policy-content-details !mb-0">
              เราอาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลภายนอกในกรณีดังต่อไปนี้ :
            </p>
            <ul className="list-disc pl-[2rem]">
              <li>
                <p>
                  กับบริษัทในเครือหรือพาร์ทเนอร์ธุรกิจเพื่อวัตถุประสงค์ที่ระบุไว้ข้างต้น
                </p>
              </li>
              <li>
                <p>
                  เมื่อมีความจำเป็นตามกฎหมายหรือคำสั่งศาล
                </p>
              </li>
              <li>
                <p>
                  เพื่อป้องกันการฉ้อโกงหรือเสี่ยงต่อการถูกฉ้อโกง
                </p>
              </li>
            </ul>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การเข้าถึงและการแก้ไขข้อมูล
            </h3>
            <p className="policy-content-details">
              ท่านมีสิทธิ์เข้าถึงและขอแก้ไขข้อมูลส่วนบุคคลของท่านที่เราเก็บรวบรวมไว้.<br />
              ท่านสามารถติดต่อเราผ่านทางอีเมลหรือทางโทรศัพท์ที่ระบุไว้ในเว็บไซต์เพื่อทำการร้องขอนี้.
            </p>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              ความปลอดภัยของข้อมูล
            </h3>
            <p className="policy-content-details">
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด ให้ความสำคัญอย่างยิ่งกับความปลอดภัยของข้อมูลส่วนบุคคลของท่านและได้ดำเนินการตามมาตรการที่เข้มงวด
              เพื่อป้องกันการสูญหาย การเข้าถึง การเปิดเผย การเปลี่ยนแปลง หรือการทำลายข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต.
            </p>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              มาตรการความปลอดภัยที่เราใช้ :
            </h3>
            <ul className="list-disc pl-[2rem]">
              <li>
                <p>
                  <span>
                    การเข้ารหัสข้อมูล : &nbsp;
                  </span>
                  เราใช้การเข้ารหัสข้อมูลในการส่งหรือรับข้อมูลส่วนบุคคลบนเว็บไซต์ของเราเพื่อช่วยปกป้องข้อมูลของท่าน.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    การจำกัดการเข้าถึงข้อมูล : &nbsp;
                  </span>
                  เราจำกัดการเข้าถึงข้อมูลส่วนบุคคลของท่านเฉพาะพนักงาน ผู้รับเหมา และตัวแทนที่ต้องการทราบข้อมูล
                  เพื่อประมวลผลตามงานที่ได้รับมอบหมาย..
                </p>
              </li>
              <li>
                <p>
                  <span>
                    การฝึกอบรมพนักงาน : &nbsp;
                  </span>
                  เราจัดการฝึกอบรมเกี่ยวกับความปลอดภัยและการป้องกันข้อมูลส่วนบุคคลให้กับพนักงานของเรา
                  เพื่อให้แน่ใจว่าพวกเขาเข้าใจความสำคัญของการป้องกันข้อมูลส่วนบุคคล.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    การตรวจสอบความปลอดภัย : &nbsp;
                  </span>
                  เราดำเนินการตรวจสอบความปลอดภัยเป็นประจำเพื่อตรวจหาและแก้ไขช่องโหว่ที่อาจเกิดขึ้น
                  เพื่อป้องกันข้อมูลของท่านจากการถูกคุกคาม.
                </p>
              </li>
            </ul>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การตอบสนองต่อการละเมิดข้อมูล
            </h3>
            <p className="policy-content-details">
              ในกรณีที่เกิดการละเมิดข้อมูลส่วนบุคคล บริษัท จะดำเนินการอย่างรวดเร็วเพื่อระบุ ตรวจสอบ และแก้ไขเหตุการณ์ และจะแจ้งให้ผู้ที่ได้รับผลกระทบทราบโดยเร็วที่สุดตามที่กฎหมายกำหนด.
            </p>
          </div>
          <div className="privacy-policy-content">
            <h3 className="policy-content-title">
              การเปลี่ยนแปลงนโยบายความปลอดภัยของข้อมูล
            </h3>
            <p className="policy-content-details">
              เราอาจปรับปรุงนโยบายความปลอดภัยของข้อมูลนี้เป็นครั้งคราวเพื่อสะท้อนถึงการเปลี่ยนแปลงในการดำเนินงานหรือตามกฎหมายที่เกี่ยวข้อง. เราจะแจ้งให้ท่านทราบเกี่ยวกับการเปลี่ยนแปลงดังกล่าวผ่านเว็บไซต์ของเรา.
              หากท่านมีคำถามหรือข้อกังวลใดๆ เกี่ยวกับความปลอดภัยของข้อมูลส่วนบุคคลของท่าน โปรดติดต่อเราที่ช่องทางที่ได้ระบุไว้ในส่วนการติดต่อของนโยบายความเป็นส่วนตัวนี้.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy;