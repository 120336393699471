import React from 'react'
import "./cookiePolicy.css";

const CookiePolicy: React.FC = () => {
  return (
    <section className="cookie-policy-wrapper bg-white">
      <div className="block-cookie-policy lg:container lg:mx-auto">
        <h2 className="title-cookie-policy">นโยบายการใช้คุกกี้</h2>
        <div className="px-[1rem] py-[2rem] max-[430px]:py-[1.5rem]">
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              การใช้งานคุกกี้และเทคโนโลยีติดตาม
            </h3>
            <p className="policy-content-details">
              เพื่อเพิ่มประสิทธิภาพและประสบการณ์ของผู้ใช้บนเว็บไซต์ "เพื่อนรับเหมา", บริษัท คอนกรีต เดลิเวอรี่ จำกัด อาจใช้คุกกี้ (Cookies) และเทคโนโลยีติดตามอื่นๆ เช่น เว็บบีคอน (Web Beacons) หรือ พิกเซล (Pixels) เพื่อรวบรวมข้อมูลที่เกี่ยวข้องกับการใช้งานเว็บไซต์ของท่าน.
            </p>
          </div>
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              คุกกี้คืออะไร?
            </h3>
            <p className="policy-content-details">
              คุกกี้เป็นไฟล์ขนาดเล็กที่เว็บไซต์สร้างขึ้นและจัดเก็บในคอมพิวเตอร์หรืออุปกรณ์มือถือของท่านเมื่อท่านเข้าชมเว็บไซต์.<br />
              คุกกี้ช่วยให้เว็บไซต์จำประสบการณ์และการตั้งค่าของท่าน เพื่อให้ท่านสามารถใช้งานเว็บไซต์ได้อย่างสะดวกสบายยิ่งขึ้นในครั้งต่อไปที่เข้าชม.
            </p>
          </div>
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              เราใช้คุกกี้เพื่อวัตถุประสงค์ใด?
            </h3>
            <ul className="list-disc pl-[2rem]">
              <li>
                <p>
                  <span>
                    คุกกี้ที่จำเป็น :&nbsp;
                  </span>
                  เพื่อให้เว็บไซต์และบริการของเราสามารถทำงานได้อย่างถูกต้อง.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    คุกกี้ด้านประสิทธิภาพ :&nbsp;
                  </span>
                  เพื่อวิเคราะห์วิธีที่ผู้ใช้ใช้งานเว็บไซต์ของเราเช่น หน้าที่ผู้ใช้เยี่ยมชมบ่อยที่สุด.
                </p>
              </li>
              <li>
                <p>
                  <span>
                    คุกกี้ด้านการโฆษณา :&nbsp;
                  </span>
                  เพื่อแสดงโฆษณาที่เกี่ยวข้องและตรงกับความสนใจของท่าน.
                </p>
              </li>
            </ul>
          </div>
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              การจัดการคุกกี้
            </h3>
            <p className="policy-content-details">
              ท่านมีสิทธิ์เลือกไม่รับคุกกี้โดยการตั้งค่าในเบราว์เซอร์ของท่านให้ปฏิเสธคุกกี้. อย่างไรก็ตาม, <br />
              โปรดทราบว่าการทำเช่นนี้อาจทำให้ท่านไม่สามารถใช้งานเว็บไซต์ได้อย่างเต็มที่.
            </p>
          </div>
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว
            </h3>
            <p className="policy-content-details">
              เราอาจปรับปรุงนโยบายความเป็นส่วนตัวนี้เป็นครั้งคราวเพื่อสะท้อนถึงการเปลี่ยนแปลงในการดำเนินงานหรือตามกฎหมายที่เกี่ยวข้อง.<br />
              เราจะแจ้งให้ท่านทราบเกี่ยวกับการเปลี่ยนแปลงดังกล่าวผ่านเว็บไซต์ของเรา.
            </p>
          </div>
          <div className="cookie-policy-content">
            <h3 className="policy-content-title">
              การติดต่อ
            </h3>
            <p className="policy-content-details">
              หากท่านมีคำถามหรือข้อกังวลใดๆ เกี่ยวกับนโยบายความเป็นส่วนตัวหรือการจัดการข้อมูลส่วนบุคคลของท่าน โปรดติดต่อเราที่ :<br />
              บริษัท คอนกรีต เดลิเวอรี่ จำกัด<br />
              ที่อยู่ : 37/1 หมู่ที่2 ตำบลหนองปลาไหล อำเภอบางละมุง จังหวัดชลบุรี 20150<br />
              โทรศัพท์ : 0929129479
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CookiePolicy;