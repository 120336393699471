import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiServices } from "../../services/endpoints/ApiEndpoint";
import { removeLocalStorage, setLocalStorage } from "../../utils/helper";
import "./Profile.css";
import { useLoading } from "../../redux/LoadingContext";

const Profile: React.FC = () => {
  const { AuthInfoAsync } = useApiServices()
  const { isLoading } = useLoading();
  const [profileUser, setProfileUser] = useState<{
    name: string;
    email: string;
  }>();
  let mockLength = 0;
  const navigate = useNavigate();
  useEffect(() => {
    console.log('isLoading', isLoading);

  }, [isLoading])

  const menuLinkPage = [
    {
      id: 1,
      nameTH: "แก้ไขข้อมูลใบกำกับภาษี",
      nameEN: "Edit tax invoice information",
      class: "taxinvoice-information-edit",
      link: "/taxinvoice-information/edit",
      icon: null,
    },
    {
      id: 2,
      nameTH: "เกี่ยวกับเรา",
      nameEN: "about us",
      class: "about-us",
      link: "/about-us",
      icon: null,
    },
    {
      id: 3,
      nameTH: "เปลี่ยนรหัสผ่าน",
      nameEN: "Change password",
      class: "change-password",
      link: "/reset-password",
      icon: null,
    },
  ];

  const onLogout = (): void => {
    removeLocalStorage("accessToken");
    removeLocalStorage("refreshToken");
    removeLocalStorage("expireTime");
    removeLocalStorage("userId");
    navigate("/login");
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const res = await AuthInfoAsync();
        if (res.isSuccess) {
          setProfileUser({
            name: `${res.result.firstName} ${res.result.lastName}`,
            email: res.result.email,
          });
          setLocalStorage("userId", res?.result?.id ?? null);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <section className="block-profile-wrapper">
      <img
        className="bg-header"
        src={"/assets/images/bg-header.png"}
        alt="bg-header"
      />
      <div className="lg:container lg:mx-auto block-profile">
        <h1 className="title-profile-page">ข้อมูลส่วนตัว</h1>
        <div className="profile-wrapper item-wrapper">
          <div className="profile">
            <div className="block-avatar">
              {mockLength !== 0 ? (
                <img
                  className="avatar"
                  src="/assets/icons/icon-btn-next-bg-white.svg"
                  alt="img-avatar"
                />
              ) : (
                <img
                  className="avatar"
                  src="/assets/icons/icon-user.svg"
                  alt="icon-user"
                />
              )}
            </div>
            <div className="block-name-email">
              <p className="welcome">
                ยินดีต้อนรับ<span className="name">{profileUser?.name}</span>
              </p>
              <p className="email">{profileUser?.email}</p>
            </div>
          </div>
          <a href="/profile/edit" className="link link-edit-profile">
            <img
              className="icon-edit"
              src="/assets/icons/icon-edit.svg"
              alt="icon-edit"
            />
          </a>
        </div>

        <a href="/order" className="order-history-wrapper item-wrapper items-link-page">
          <div className="block-order-history item-link-name">
            <img
              className="item-link-icon icon-list-order-history"
              src="/assets/icons/icon-list-order-history.svg"
              alt="icon-list-order-history"
            />
            <p className="item-link-title order-history">ประวัติการสั่งซื้อ</p>
          </div>
          <div
            className="link btn-item-link link-order-history"
          >
            <img
              className="icon-next-orange"
              src="/assets/icons/icon-next-orange.svg"
              alt="icon-next-orange"
            />
          </div>
        </a>

        <div className="social-media-wrapper item-wrapper">
          <h2 className="title-social-media">การเชื่อมต่อ</h2>
          <div className="block-social-media">
            <div className="social-media line">
              <span className="social-media-left">
                <img
                  className="icon-media icon-line"
                  src="/assets/icons/icon-line.svg"
                  alt="icon-line"
                />
                <p className="media-name">Line</p>
              </span>
              <span className="social-media-right">
                <input type="checkbox" className="toggle toggle-success" />
                <p className="title-toggle">เชื่อมต่อ</p>
              </span>
            </div>

            <div className="social-media gmail">
              <span className="social-media-left">
                <img
                  className="icon-media icon-gmail"
                  src="/assets/icons/icon-gmail.svg"
                  alt="icon-gmail"
                />
                <p className="media-name">Email</p>
              </span>
              <span className="social-media-right">
                <input type="checkbox" className="toggle toggle-success" />
                <p className="title-toggle">เชื่อมต่อ</p>
              </span>
            </div>

            <div className="social-media facebook">
              <span className="social-media-left">
                <img
                  className="icon-media icon-facebook"
                  src="/assets/icons/icon-facebook.svg"
                  alt="icon-facebook"
                />
                <p className="media-name">Facebook</p>
              </span>
              <span className="social-media-right">
                <input type="checkbox" className="toggle toggle-success" />
                <p className="title-toggle">เชื่อมต่อ</p>
              </span>
            </div>
          </div>
        </div>
        {/* menuLink */}
        {/* <div className="edit-taxinvoice-wrapper item-wrapper items-link-page">
            <div className="block-edit-taxinvoice item-link-name">
              <img className="item-link-icon icon-user" src="/assets/icons/icon-user-01.svg" alt="icon-user-01" />
              <p className="item-link-title edit-taxinvoice">แก้ไขข้อมูลใบกำกับภาษี</p>
            </div>
            <a href="/edit-tax-invoice-information" className="link btn-item-link link-edit-taxinvoice">
              <img className="icon-next-orange" src="/assets/icons/icon-next-orange.svg" alt="icon-next-orange" />
            </a>
          </div> */}

        {/* <div className="about-us-wrapper item-wrapper items-link-page">
            <div className="block-about-us item-link-name">
              <img className="item-link-icon icon-user" src="/assets/icons/icon-user-01.svg" alt="icon-user-01" />
              <p className="item-link-title about-us">เกี่ยวกับเรา</p>
            </div>
            <a href="/about-us" className="link btn-item-link link-about-us">
              <img className="icon-next-orange" src="/assets/icons/icon-next-orange.svg" alt="icon-next-orange" />
            </a>
          </div> */}

        {/* <div className="about-us-wrapper item-wrapper items-link-page">
            <div className="block-about-us item-link-name">
              <img className="item-link-icon icon-user" src="/assets/icons/icon-user-01.svg" alt="icon-user-01" />
              <p className="item-link-title about-us">เกี่ยวกับเรา</p>
            </div>
            <a href="/about-us" className="link btn-item-link link-about-us">
              <img className="icon-next-orange" src="/assets/icons/icon-next-orange.svg" alt="icon-next-orange" />
            </a>
          </div> */}

        {menuLinkPage.map((menuLink: any, i: number) => (
          <a
            key={i}
            href={menuLink.link}
            className={`${menuLink.class}-wrapper item-wrapper items-link-page`}
          >
            <div className={`block-${menuLink.class} item-link-name`}>
              <img
                className="item-link-icon icon-user"
                src="/assets/icons/icon-user-01.svg"
                alt="icon-user-01"
              />
              <p className={`item-link-title ${menuLink.class}`}>
                {menuLink.nameTH}
              </p>
            </div>
            <div className="link btn-item-link link-about-us">
              {menuLink.icon === null ? (
                <img
                  className="icon-next-orange"
                  src="/assets/icons/icon-next-orange.svg"
                  alt="icon-next-orange"
                />
              ) : (
                <img
                  className={menuLink.icon}
                  src={`/assets/icons/${menuLink.icon}.svg`}
                  alt={menuLink.icon}
                />
              )}
            </div>
          </a>
        ))}

        <div
          className="log-out-wrapper item-wrapper items-link-page"
          onClick={() => onLogout()}
        >
          <div className="block-log-out item-link-name">
            <img
              className="item-link-icon icon-log-out"
              src="/assets/icons/icon-log-out.svg"
              alt="icon-log-out"
            />
            <p className="item-link-title log-out">ออกจากระบบ</p>
          </div>
          <button type="button" className="link btn-item-link btn-log-out">
            <img
              className="icon-next-orange"
              src="/assets/icons/icon-next-orange.svg"
              alt="icon-next-orange"
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Profile;
