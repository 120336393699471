import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { ENCRYPT_SECRET_KEY } from "./constants";

export const isMobile = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const isDesktop = (): boolean => {
  return !isMobile();
};

export const encryptData = (data: object | string): string => {
  try {
    const ciphertext = AES.encrypt(JSON.stringify(data), ENCRYPT_SECRET_KEY);
    return ciphertext.toString();
  } catch (error) {
    return ""
  }
};

export const decryptData = (ciphertext: string) => {
  try {
    const bytes = AES.decrypt(ciphertext, ENCRYPT_SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(Utf8));
    return decryptedData;
  } catch (error) {
    return null
  }

};

export const setLocalStorage = (key: string, data: object | string): void => {
  window.localStorage.setItem(key, encryptData(data));
};

export const getLocalStorage = (key: string) => {
  return decryptData(window.localStorage.getItem(key) || "");
};

export const setSessionStorage = (key: string, data: object): void => {
  window.sessionStorage.setItem(key, encryptData(data));
};

export const getSessionStorage = (key: string) => {
  return decryptData(window.sessionStorage.getItem(key) || "");
};

export const removeLocalStorage = (key: string):void => {
  try {
    return window.localStorage.removeItem(key);
  } catch (error) {
    return    
  }
};

export const clearLocalStorage = ():void => {
  try {
    return window.localStorage.clear();
  } catch (error) {
    return    
  }
};

export const hasVisitedWelcomePage = (): boolean => {
  try {
    return getLocalStorage('hasVisitedWelcomePage') === 'true';
  } catch (error) {
    return false;
  }
};

export const accessRoute = (): boolean => {
  try {
    // const accessToken = getLocalStorage('accessToken');
    const userId = getLocalStorage('userId');
    return !!userId;
  } catch (error) {
    return false;
  }
};
