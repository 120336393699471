import React, { useState } from 'react';
import './ProductListCart.css';

const ProductListCart: React.FC = () => {

  const [numberProducts, setNumberProducts] = useState<number>(1);

  const increaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber < 999 ? prevNumber + 1 : 999));
  };

  const decreaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber > 1 ? prevNumber - 1 : 1));
  };

  return (
    <section className="product-list-cart-wrapper lg:container lg:mx-auto">
      <div className="block-product-items-list">
        <div className="product-item mb-6">
          <div className="item-title">
            <input type="checkbox" defaultChecked className="checkbox title-checkbox" />
            <h2 className="title-name">บริษัท การก่อสร้าง จำกัด</h2>
          </div>
          <div className="items-list">
            {/* item 1 */}
            <div className="items-list-detail">
              <div className="detail-image">
                <img src="/assets/images/no-image-product.png" alt="no-image-product" className="w-full rounded-xl" />
              </div>
              <div className="detail-content">
                <p className="content-name">เหล็ก WF-BEAM 300x150x6.5x9มม. 9 เมตร SS400</p>
                <div className="block-number-products">
                  <div className="number-products">
                    <label className="input">
                      <button className="btn decrease-number" onClick={decreaseNumber}>
                        -
                      </button>
                      <input
                        type="number"
                        placeholder="999"
                        className="input input-number-products"
                        value={numberProducts}
                        onChange={e => setNumberProducts(Math.min(parseInt(e.target.value, 10), 999))}
                      />
                      <button className="btn increase-number" onClick={increaseNumber}>
                        +
                      </button>
                    </label>
                  </div>
                </div>
                <p className="product-price-unit">1,000.00 THB</p>
              </div>
              <div className="items-list-btn">
                <button className="btn btn-icon-edit">
                  <img src="/assets/icons/icon-edit-01.svg" alt="icon-edit-01" className="icon icon-edit" />
                </button>
                <button className="btn btn-icon-delete">
                  <img src="/assets/icons/icon-delete.svg" alt="icon-delete" className="icon icon-delete" />
                </button>
              </div>
            </div>
            {/* item 2 */}
            <div className="items-list-detail">
              <div className="detail-image">
                <img src="/assets/images/no-image-product.png" alt="no-image-product" className="w-full rounded-xl" />
              </div>
              <div className="detail-content">
                <p className="content-name">เหล็ก WF-BEAM 300x150x6.5x9มม. 9 เมตร SS400</p>
                <div className="block-number-products">
                  <div className="number-products">
                    <label className="input">
                      <button className="btn decrease-number" onClick={decreaseNumber}>
                        -
                      </button>
                      <input
                        type="number"
                        placeholder="999"
                        className="input input-number-products"
                        value={numberProducts}
                        onChange={e => setNumberProducts(Math.min(parseInt(e.target.value, 10), 999))}
                      />
                      <button className="btn increase-number" onClick={increaseNumber}>
                        +
                      </button>
                    </label>
                  </div>
                </div>
                <p className="product-price-unit">1,000.00 THB</p>
              </div>
              <div className="items-list-btn">
                <button className="btn btn-icon-edit">
                  <img src="/assets/icons/icon-edit-01.svg" alt="icon-edit-01" className="icon icon-edit" />
                </button>
                <button className="btn btn-icon-delete">
                  <img src="/assets/icons/icon-delete.svg" alt="icon-delete" className="icon icon-delete" />
                </button>
              </div>
            </div>
            <div className="items-total">
              <p className='total-name'>ยอดรวมชำระ</p>
              <p className="total-price">3,000.00 THB</p>
            </div>
          </div>
        </div>
      </div>
      <div className="block-btn-check-out">
        <button className="btn btn-check-out">
          เช็คเอาท์
        </button>
      </div>
    </section>
  )
}

export default ProductListCart