export enum SmsOtpType {
    Register = 1,
    ResetPassword = 2
}

export enum UserTypeId {
    Admin = 0,
    Customer = 1,
    Seller = 2,
    Guest = 3
}