import React, { useState } from 'react'
import "./error.css";

const Error: React.FC = () => {

  return (
    <section className="error-wrapper bg-white">
      <div className="block-error">
        <div className="block-image">
          <img
            className="image-warning"
            src="/assets/images/image-warning.png"
            alt="image-warning"
          />
        </div>
        <h2 className="error-title">
          โอ้ะ โอ!
          <span>
            มีบางอย่างผิดพลาด
          </span>
        </h2>
        <p className="error-sub-title">
          คุณอาจจะรีเฟรชหน้าเพจใหม่
          <span>
            หรือลองใหม่อีกครั้ง
          </span>
        </p>
        <button className="btn btn-back-home">
          กลับสู่หน้าหลัก
          <img
            className="icon-arrow-right"
            src="/assets/icons/icon-arrow-right.svg"
            alt="icon-arrow-right"
          />
        </button>
      </div>
    </section>
  )
}

export default Error;