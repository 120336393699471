import React from "react";
import './FooterDesktop.css';
interface FooterProps {
  pathname: string;
}
const FooterDesktop: React.FC<FooterProps> = ({ pathname }) => {
  const checkPathForHide = (pathname: string) => {
    switch (pathname) {
      case "/login":
        return "hidden";
      case "/register-otp":
        return "hidden";
      case "/reset-password-otp":
        return "hidden"
      case "/reset-password":
        return "hidden";
      case "/register":
        return "hidden";
      case "/welcome":
        return "hidden";
      default:
        return ""
    }
  }

  return (
    <footer className={`footer-desktop-wrapper bg-footer ${checkPathForHide(pathname)}`}>
      <div className="block-footer-desktop lg:container lg:mx-auto">
        <div className="footer-desktop-top">
          <div className="block-image">
            <a className="link" href="/">
              <img
                className="logo-footer"
                src="/assets/logo/logo-brand-vertical.png"
                alt="logo-brand-vertical"
              />
            </a>
          </div>
          <div className="desktop-top-items">
            <h2 className="items-text text-bold">บริษัท คอนกรีต เดลิเวอรี่ จำกัด (สำนักงานใหญ่)</h2>
            <p className="items-text">37/1 หมู่ที่ 2 ตำบลหนองปลาไหล</p>
            <p className="items-text">อำเภอบางละมุง จังหวัดชลบุรี</p>
          </div>
          <div className="desktop-top-items">
            <a className="link link-hover link-phone">
              <img
                className="link-icon"
                src="/assets/icons/icon-phone-white.svg"
                alt="icon-phone-white"
              />
              <span className="link-text">
                092-912-9479
              </span>
            </a>
            <a className="link link-hover link-line">
              <img
                className="link-icon"
                src="/assets/icons/icon-line-official.svg"
                alt="icon-line-official"
              />
              <span className="link-text">
                Line Official : @concrete_d
              </span>
            </a>
          </div>
        </div>
        <div className="footer-desktop-bottom">
          <h2 className="desktop-bottom-left">© สงวนลิขสิทธิ์ พ.ศ. 2564 บริษัท คอนกรีต เดลิเวอรี่ จำกัด</h2>
          <div className="desktop-bottom-right">
            <h2 className="bottom-text-items">
              ข้อกำหนดและเงื่อนไข
            </h2>
            <h2 className="bottom-text-items">
              นโยบายความเป็นส่วนตัว
            </h2>
            <h2 className="bottom-text-items">
              นโยบายการใช้คุกกี้
            </h2>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterDesktop;
