import React from 'react';
import './Order.css';

const Order: React.FC = () => {

  let statusOrder = "wait";

  return (
    <section className="order-wrapper lg:container lg:mx-auto">
      <h1 className="title-order-page">ประวัติการสั่งซื้อ</h1>
      <div className="block-order-list">
        <div className={`title-order ${statusOrder}`}>รอชำระ</div>
        <div className="order-number-date">
          <img className="image-address" src="/assets/images/image-address.png" alt="image-address" />
          <div className="number-date">
            <p className="order-number">
              Order Number :
              <span className="value">
                20231130001
              </span>
            </p>
            <p className="date">
              วันที่สั่งซื้อ :
              <span className="value">
                17/01/2024, 10:34
              </span>
            </p>
            <p className="date">
              วันที่นัดหมาย :
              <span className="value">
                18/01/2024, 11:30
              </span>
            </p>
          </div>
        </div>
        {/* order-items-list */}
        <div className="order-items">
          <p className="items-name">เหล็กเส้นสำหรับงานก่อสร้าง</p>
          <p className="quantity-price">
            <span className="quantity">x2</span>
            <span className="price">2,000.00 THB</span>
          </p>
        </div>
        {/* Total orders */}
        <div className="order-items total-orders">
          <p className="items-name">รวมการสั่งซื้อ</p>
          <p className="quantity-price">
            <span className="quantity"></span>
            <span className="price">4,000.00 THB</span>
          </p>
        </div>
        {/* Shipping */}
        <div className="order-items shipping">
          <p className="items-name">การจัดส่ง</p>
          <p className="quantity-price">
            <span className="quantity"></span>
            <span className="price">900.00 THB</span>
          </p>
        </div>
        {/* Total */}
        <div className="order-items total">
          <p className="total-text">ยอดรวมชำระ</p>
          <p className="total-price">2,000.00 THB</p>
        </div>
      </div>
    </section>
  )
}

export default Order;