import React, { useRef } from 'react';
import "./taxInvoiceInformationEdit.css";

const TaxInvoiceInformationEdit: React.FC = () => {

  return (
    <section className="tax-Invoice-information-edit-wrapper bg-white">
      <div className="block-tax-Invoice-information-edit lg:container lg:mx-auto">
        <h2 className="title-tax-Invoice-information-edit">แก้ไขข้อมูลใบกำกับภาษี</h2>
        <form>
          <div className="block-form-tax-Invoice-information-edit">
            <div className="form-contact">
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">ชื่อผู้เสียภาษี</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกชื่อผู้เสียภาษี"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">เลขประจำตัวผู้เสียภาษี</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกเลขประจำตัวผู้เสียภาษี"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">เบอร์ติดต่อ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกเบอร์ติดต่อ"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">ที่อยู่</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกที่อยู่"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">ตำบล</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกตำบล"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">อำเภอ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกอำเภอ"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">จังหวัด</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกจังหวัด"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control right">
                <div className="label">
                  <span className="label-text required">รหัสไปรษณีย์</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกรหัสไปรษณีย์"
                  className="input input-bordered"
                />
              </label>
            </div>
          </div>
        </form>
        <div className="btn-group py-5">
          <button className="btn btn-tax-Invoice-information-edit">
            ยืนยัน
          </button>
        </div>
      </div>
    </section>
  )
}

export default TaxInvoiceInformationEdit;