import React, { useState } from 'react';
import './ViewDetailProduct.css';
import { useNavigate } from 'react-router-dom';

const ViewDetail: React.FC = () => {
  const navigate = useNavigate();
  const [numberProducts, setNumberProducts] = useState<number>(1);


  const increaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber < 999 ? prevNumber + 1 : 999));
  };

  const decreaseNumber = () => {
    setNumberProducts(prevNumber => (prevNumber > 1 ? prevNumber - 1 : 1));
  };

  return (
    <section className="block-view-detail-wrapper lg:container lg:mx-auto">
      <div className="title-view-detail-page">
        <p className="title">รายละเอียดสินค้า</p>
      </div>
      <div className="block-product-details">
        <div className="card card-product-details">
          <figure className="block-image-product rounded-xl shadow-[0_2px_24px_0px_#0000001A]">
            <img src="/assets/images/no-image-product.png" alt="no-image-product" className="w-full rounded-xl" />
          </figure>
          <div className="card-body">
            <h2 className="card-title">เหล็ก WF-BEAM 300x150x6.5x9มม. 9 เมตร SS400</h2>
            <div className="list-product">
              <h3 className="list-product-title">เลือก</h3>
              <div className="form-control form-list-product">
                <label className="label cursor-pointer">
                  <input type="radio" name="radio-10" className="radio" defaultChecked />
                  <span className="label-text">บริษัท A - 1960 บาท </span>
                </label>
              </div>
              <div className="form-control form-list-product">
                <label className="label cursor-pointer">
                  <input type="radio" name="radio-10" className="radio" />
                  <span className="label-text">บริษัท B - 2960 บาท </span>
                </label>
              </div>
              <div className="form-control form-list-product">
                <label className="label cursor-pointer">
                  <input type="radio" name="radio-10" className="radio" />
                  <span className="label-text">บริษัท C - 3960 บาท </span>
                </label>
              </div>
              <div className="form-control form-list-product">
                <label className="label cursor-pointer">
                  <input type="radio" name="radio-10" className="radio" />
                  <span className="label-text">บริษัท D - 4960 บาท </span>
                </label>
              </div>
            </div>
            <div className="block-number-products">
              <h2 className="number-products-title">รายละเอียด</h2>
              <div className="number-products">
                <label className="input">
                  <button className="btn decrease-number" onClick={decreaseNumber}>
                    -
                  </button>
                  <input
                    type="number"
                    placeholder="999"
                    className="input input-number-products"
                    value={numberProducts}
                    onChange={e => setNumberProducts(Math.min(parseInt(e.target.value, 10), 999))}
                  />
                  <button className="btn increase-number" onClick={increaseNumber}>
                    +
                  </button>
                </label>
              </div>
            </div>
            <div className="card-actions btn-add-to-cart">
              <button className="btn">เพิ่มไปยังตะกร้า</button>
            </div>
          </div>
        </div>
      </div>
      <div className="block-notation">
        <label className="form-control form-notation">
          <div className="label">
            <span className="label-text">หมายเหตุ</span>
          </div>
          <textarea className="textarea textarea-bordered h-24" placeholder="หมายเหตุ"></textarea>
        </label>
      </div>
      <div className="block-key-highlight">
        <h2 className="key-highlight-title">Key Highlight</h2>
        <ul className="key-highlight-list">
          <li>สินค้าคุณภาพดี แข็งแรงทนทาน มีมาตรฐาน</li>
          <li>ผลิตจากเหล็กคุณภาพดีเยี่ยม</li>
          <li>สินค้ามีความหนา แข็งแรง ทนทาน ใช้งานได้ยาวนาน คุ้มค่า</li>
          <li>เหล็กไวด์แฟลงจ์ WF เด่นชัดคือขนาดท่อนกลาง ยาวกว่าปีก จะนำไปใช้ในงานก่อสร้างอาคาร เป็นชิ้นส่วนของ เสา คาน โครงหลังคา</li>
        </ul>
      </div>
      <div className="btn-go-back">
        <button className="btn" onClick={() => navigate('/')}>ย้อนกลับ</button>
      </div>
    </section>
  )
}

export default ViewDetail;