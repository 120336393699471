import React from 'react'
import './map-input.css'
interface MapInputProps {
    isExpended: boolean,
    onToggleExpend: () => any,
    default?: string
}
const MapInput: React.FC<MapInputProps> = (props) => {
    const locaionList = [
        {
            name: "ก๋วยเตี๋ยว ดู๋ดีชักธง",
            address: "379/2-3, เมืองขอนแก่น, จังหวัดขอนแก่น 40000",
            lat: 1,
            lng: 1
        },
        {
            name: "รื่นรมย์นมสด",
            address: "จังหวัดขอนแก่น",
            lat: 1,
            lng: 1
        },
        {
            name: "Good Afternoon",
            address: "เมืองขอนแก่น, จังหวัดขอนแก่น 40000",
            lat: 1,
            lng: 1
        },
        {
            name: "เซเว่นอีเลเว่น",
            address: "",
            lat: 1,
            lng: 1
        },
    ]

    let ArrowUp = '/assets/icons/ArrowUp.svg';
    let ArrowDown = '/assets/icons/ArrowDown.svg';

    return (
        <div className='card-map-wrapper'>
            <div className='card-map-content'>
                <img alt='arrow-icons' onClick={() => props.onToggleExpend()} width={'40px'} src={props.isExpended ? ArrowDown : ArrowUp} />
                {!props.isExpended && <div className='text-title'><span>ค้นหาสถานที่ใกล้เคียง</span></div>}
                <input
                    type="text"
                    placeholder="ค้นหา"
                    className="input input-bordered mt-2"
                />
                {
                    props.isExpended &&
                    <div className='card-map-content-list'>
                        {
                            locaionList.map(item =>
                                <div className='address-item'>
                                    <div className='address-title'>{item.name}</div>
                                    <span>{item.address}</span>
                                </div>
                            )
                        }
                    </div>
                }
            </div>


        </div>
    )
}

export default MapInput