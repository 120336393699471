import React from 'react';
import { useLoading } from '../../redux/LoadingContext';
import './LoadingScreen.css';

const LoadingScreen = () => {
    const { isLoading } = useLoading();

    if (!isLoading) {
        return null;
    }

    return (
        <div className="loading-screen">
            <div className="spinner"></div>
        </div>
    );
};

export default LoadingScreen;
