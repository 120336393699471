import React from 'react';
import './footer.css';
import { useNavigate } from 'react-router-dom';

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const isActive = (path: string): boolean => {
    return path === window.location.pathname
  }

  let HomeIcon = '/assets/icons/HomeIcon.svg';
  let HomeIconActive = '/assets/icons/HomeIconActive.svg';
  let CartIcon = '/assets/icons/CartIcon.svg';
  let CartIconActive = '/assets/icons/CartIconActive.svg';
  let OrderIcon = '/assets/icons/OrderIcon.svg';
  let OrderIconActive = '/assets/icons/OrderIconActive.svg';
  let ProfileIcon = '/assets/icons/ProfileIcon.svg';
  let ProfileIconActive = '/assets/icons/ProfileIconActive.svg';

  return (
    <footer className="footer-mobile">
      <button className={`footer-button ${isActive('/') ? 'active' : ''}`} onClick={() => navigate('/')}>
        <img src={isActive('/') ? HomeIconActive : HomeIcon} alt="Home" className="icon" />
        หน้าหลัก
      </button>
      <button className={`footer-button ${isActive('/cart') ? 'active' : ''}`} onClick={() => navigate('/cart')}>
        <img src={isActive('/cart') ? CartIconActive : CartIcon} alt="Cart" className="icon" />
        รถเข็น
      </button>
      <button className={`footer-button ${isActive('/orders') ? 'active' : ''}`} onClick={() => navigate('/orders')}>
        <img src={isActive('/orders') ? OrderIconActive : OrderIcon} alt="Orders" className="icon" />
        ออเดอร์
      </button>
      <button className={`footer-button ${isActive('/profile') ? 'active' : ''}`} onClick={() => navigate('/profile')}>
        <img src={isActive('/profile') ? ProfileIconActive : ProfileIcon} alt="Profile" className="icon" />
        โปร์ไฟล์
      </button>
    </footer>
  );
}

export default Footer;
