import { useLoading } from "../../redux/LoadingContext";
import {
    payloadLogin,
    payloadRegister,
    payloadRequestOtp,
    payloadResetPassword,
    payloadUpdateUser,
    payloadVerifyOtp
} from "../../utils/models";
import getBaseService from "../base-service/BaseService";


const urlAuth = 'authen';
const urlSms = 'sms';
const urlUser = 'users';

export const useApiServices = () => {
    const { setLoading } = useLoading();
    const BaseService = getBaseService(setLoading);

    // ========================================================= //
    // =================== Authentication ====================== //
    // ========================================================= //
    const LoginAsync = async (payload: payloadLogin) => {
        return await BaseService.post(`${urlAuth}/login`, payload);
    };
    const AuthRefreshAsync = async () => {
        return await BaseService.get(`${urlAuth}/refresh`);
    };
    const AuthInfoAsync = async () => {
        return await BaseService.get(`${urlAuth}/info`);
    };
    const RegisterAsync = async (payload: payloadRegister) => {
        return await BaseService.post(`${urlAuth}/register`, payload);
    };
    const ResetPasswordAsync = async (payload: payloadResetPassword) => {
        return await BaseService.post(`${urlAuth}/resetpassword`, payload);
    };

    // ========================================================= //
    // ======================== Sms ============================ //
    // ========================================================= //
    const RequestOtpAsync = async (payload: payloadRequestOtp) => {
        return await BaseService.post(`${urlSms}/request`, payload);
    };
    const VerifyOtpAsync = async (payload: payloadVerifyOtp) => {
        return await BaseService.post(`${urlSms}/verify`, payload);
    };

    // ========================================================= //
    // ======================== User =========================== //
    // ========================================================= //
    const GetUserByIdAsync = async (id: string) => {
        return await BaseService.get(`${urlUser}/${id}`);
    };
    const UpdateUserByIdAsync = async (id: string, payload: payloadUpdateUser) => {
        return await BaseService.put(`${urlUser}/${id}`, payload);
    };
    const DeleteUserByIdAsync = async (id: string) => {
        return await BaseService.delete(`${urlUser}/${id}`);
    };
    const GetUserAsync = async (id: number) => {
        return await BaseService.get(`${urlUser}/${id}`);
    };
    const CreateUserAsync = async (payload: payloadRegister) => {
        return await BaseService.post(`${urlUser}`, payload);
    };

    return {
        LoginAsync,
        AuthRefreshAsync,
        AuthInfoAsync,
        RegisterAsync,
        ResetPasswordAsync,
        RequestOtpAsync,
        VerifyOtpAsync,
        GetUserByIdAsync,
        UpdateUserByIdAsync,
        DeleteUserByIdAsync,
        GetUserAsync,
        CreateUserAsync
    };
};
