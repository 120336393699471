import React, { useState } from 'react'
import "./recipientDetails.css";

const RecipientDetails: React.FC = () => {
  const [selectedETax, setSelectedETax] = useState<string>("full-e-tax");
  const [sameAddressAbove, setSameAddressAbove] = useState<boolean>(true);

  const selectETax = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedETax(event.target.value);
  };

  const onCheckSameAddress = () => {
    setSameAddressAbove(!sameAddressAbove);
  };

  return (
    <section className="recipient-details-wrapper bg-white">
      <div className="block-contact lg:container lg:mx-auto">
        <h2 className="title">รายละเอียดผู้รับสินค้า</h2>
        <form>
          <div className="block-form-recipient-details">
            <div className="form-contact">
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">ชื่อ-นามสกุล</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกชื่อ-นามสกุล"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control">
                <div className="label">
                  <span className="label-text required">เบอร์ติดต่อ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกเบอร์ติดต่อ"
                  className="input input-bordered"
                />
              </label>
              <label className="form-control !max-w-full col-span-full">
                <div className="label">
                  <span className="label-text">หมายเหตุ</span>
                </div>
                <input
                  type="text"
                  placeholder="กรอกหมายเหตุ"
                  className="input input-bordered !max-w-full"
                />
              </label>
            </div>
            <div className="form-e-tax-radio">
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-1"
                  className="radio"
                  value="full-e-tax"
                  checked={selectedETax === 'full-e-tax'}
                  onChange={selectETax}
                />
                <span className="label-text">รับใบกำกับภาษีฉบับเต็ม</span>
              </label>
              <label className="label cursor-pointer">
                <input
                  type="radio"
                  name="radio-1"
                  className="radio"
                  value="e-tax"
                  checked={selectedETax === 'e-tax'}
                  onChange={selectETax}
                />
                <span className="label-text">E-Tax</span>
              </label>
            </div>
            {selectedETax === "full-e-tax"
              ?
              <div className="form-full-e-tax">
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">ชื่อผู้เสียภาษี</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกชื่อผู้เสียภาษี"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">เลขประจำตัวผู้เสียภาษี</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกเลขประจำตัวผู้เสียภาษี"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">เบอร์ติดต่อ</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกเบอร์ติดต่อ"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">ที่อยู่</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกที่อยู่"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">ตำบล</span>
                  </div>
                  <select className="select select-bordered">
                    <option disabled selected>เลือกตำบล</option>
                    <option>Han Solo</option>
                    <option>Greedo</option>
                  </select>
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">อำเภอ</span>
                  </div>
                  <select className="select select-bordered">
                    <option disabled selected>เลือกอำเภอ</option>
                    <option>Han Solo</option>
                    <option>Greedo</option>
                  </select>
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">จังหวัด</span>
                  </div>
                  <select className="select select-bordered">
                    <option disabled selected>เลือกจังหวัด</option>
                    <option>Han Solo</option>
                    <option>Greedo</option>
                  </select>
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">รหัสไปรษณีย์</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกรหัสไปรษณีย์"
                    className="input input-bordered"
                  />
                </label>
              </div>
              :
              <div className="form-e-tax">
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">ชื่อผู้เสียภาษี</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกชื่อผู้เสียภาษี"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control">
                  <div className="label">
                    <span className="label-text required">เลขประจำตัวผู้เสียภาษี</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกเลขประจำตัวผู้เสียภาษี"
                    className="input input-bordered"
                  />
                </label>
                <label className="form-control !max-w-full col-span-full">
                  <div className="label">
                    <span className="label-text required">อีเมล</span>
                  </div>
                  <input
                    type="text"
                    placeholder="กรอกเลขประจำตัวผู้เสียภาษี"
                    className="input input-bordered"
                  />
                </label>
              </div>
            }

            {selectedETax !== "e-tax" &&
              <>
                <h2 className="annotation">
                  *หมายเหตุ : รอบการส่งใบกำกับภาษีทุกสิ้นเดือน (จะได้รับภายในสัแดาห์แรกของเดือนถัดไป)
                </h2>

                <div className="form-same-address-above-checkbox">
                  <label className="label cursor-pointer">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={!sameAddressAbove}
                      onChange={onCheckSameAddress}
                    />
                    <span className="label-text">จัดส่งตามข้อมูลใบกำกับภาษี</span>
                  </label>
                </div>
                {sameAddressAbove === true &&
                  <div className="form-e-tax">
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">ชื่อ-นามสกุล</span>
                      </div>
                      <input
                        type="text"
                        placeholder="กรอกชื่อ-นามสกุล"
                        className="input input-bordered"
                      />
                    </label>
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">เบอร์ติดต่อ</span>
                      </div>
                      <input
                        type="text"
                        placeholder="กรอกเบอร์ติดต่อ"
                        className="input input-bordered"
                      />
                    </label>
                    <label className="form-control !max-w-full col-span-full">
                      <div className="label">
                        <span className="label-text required">ที่อยู่</span>
                      </div>
                      <input
                        type="text"
                        placeholder="กรอกที่อยู่"
                        className="input input-bordered !max-w-full"
                      />
                    </label>
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">ตำบล</span>
                      </div>
                      <select className="select select-bordered">
                        <option disabled selected>เลือกตำบล</option>
                        <option>Han Solo</option>
                        <option>Greedo</option>
                      </select>
                    </label>
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">อำเภอ</span>
                      </div>
                      <select className="select select-bordered">
                        <option disabled selected>เลือกอำเภอ</option>
                        <option>Han Solo</option>
                        <option>Greedo</option>
                      </select>
                    </label>
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">จังหวัด</span>
                      </div>
                      <select className="select select-bordered">
                        <option disabled selected>เลือกจังหวัด</option>
                        <option>Han Solo</option>
                        <option>Greedo</option>
                      </select>
                    </label>
                    <label className="form-control">
                      <div className="label">
                        <span className="label-text required">รหัสไปรษณีย์</span>
                      </div>
                      <input
                        type="text"
                        placeholder="กรอกเลขประจำตัวผู้เสียภาษี"
                        className="input input-bordered"
                      />
                    </label>
                    <label className="form-control !max-w-full col-span-full">
                      <div className="label">
                        <span className="label-text">หมายเหตุ</span>
                      </div>
                      <input
                        type="text"
                        placeholder="กรอกหมายเหตุ"
                        className="input input-bordered !max-w-full"
                      />
                    </label>
                  </div>
                }
              </>
            }
          </div>
        </form>

      </div >
    </section >
  )
}

export default RecipientDetails;