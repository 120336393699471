import React, { useState, useEffect } from "react";
import "./home.css";

const Home: React.FC = () => {
  const [activeSortRows, setActiveSortRows] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [direction, setDirection] = useState<'left' | 'right'>('right');


  let nameUser = "คุณมานี มีใจ";
  let locationDetail =
    "37/1 หมู่ที่ 2 ตำบลหนองปลาไหล อำเภอบางละมุง จังหวัดชลบุรี";
  const mockProducts = [
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS400",
    },
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS401",
    },
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS402",
    },
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS403",
    },
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS403",
    },
    {
      productImage: "/assets/images/banner-home.png",
      productName: "เหล็ก WF-BEAM",
      productDetail: "300x150x6.5x9มม. 9 เมตร",
      ProductKey: "SS403",
    },
  ];

  const imagesBanner = [
    "/assets/images/test-banner-home-01.png",
    "/assets/images/test-banner-home-02.png",
    "/assets/images/test-banner-home-03.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setDirection('right');
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesBanner.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [imagesBanner.length]);

  const handleSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <section className="block-home-wrapper lg:container lg:mx-auto">
      <div className="block-name-user-location">
        <p className="name-user">
          สวัสดี &nbsp;
          <span>{nameUser}</span>
        </p>
        <div className="location">
          <button
            className="btn btn-location"
            onClick={() => {
              const modal = document.getElementById("my_modal_1");
              if (modal instanceof HTMLDialogElement) {
                modal.show();
              }
            }}
          >
            <img
              src={"/assets/icons/icon-location-blue.svg"}
              alt="icon-location"
              className="icon icon-location"
            />
            เลือกตำแหน่งที่ตั้ง
          </button>
          <dialog id="my_modal_1" className="modal home-page">
            <div className="modal-box">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                  ✕
                </button>
              </form>
              <h3 className="font-bold text-lg">Hello!</h3>
              <p className="py-4">
                Press ESC key or click on ✕ button to close
              </p>
            </div>
          </dialog>
        </div>
      </div>
      <div className="block-search-location">
        <label className="input input-bordered">
          <input type="text" className="grow" placeholder="Search" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="w-4 h-4 opacity-70"
          >
            <path
              fillRule="evenodd"
              d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
              clipRule="evenodd"
            />
          </svg>
        </label>
      </div>
      <div className="block-location-detail">
        <img
          src={"/assets/icons/icon-pin-line-orange.svg"}
          alt="icon-pin-line-orange"
          className="icon icon-pin-line-orange"
        />
        <div className="location-detail">
          <p className="title">จัดส่งที่</p>
          <p className="detail">{locationDetail}</p>
        </div>
      </div>
      <div className="block-home-banner">
        <div className="home-banner-slider">
          <div
            className="banner-slider-item"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              flexDirection: direction === 'right' ? 'row' : 'row-reverse',
            }}
          >
            {imagesBanner.map((src, index) => (
              <img src={src} className="item-image" alt={`carousel-item-${index + 1}`} />

            ))}
          </div>
          <div className="btn-banner-slider">
            {imagesBanner.map((_, index) => (
              <button
                key={index}
                className={`btn ${currentIndex === index ? 'btn-active' : ''}`}
                onClick={() => handleSlide(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="block-product-type">
        <button className="btn btn-ghost btn-type btn-type-iron">
          <div className="image-type">
            <img
              src={"/assets/icons/icon-type-iron.svg"}
              alt="icon-type-iron"
              className="icon icon-type-iron"
            />
          </div>
          <p className="type-name">เหล็ก</p>
        </button>

        <button className="btn btn-ghost btn-type btn-type-brick">
          <div className="image-type">
            <img
              src={"/assets/icons/icon-type-brick.svg"}
              alt="icon-type-brick"
              className="icon icon-type-brick"
            />
          </div>
          <p className="type-name">อิฐ</p>
        </button>

        <button className="btn btn-ghost btn-type btn-type-rock">
          <div className="image-type">
            <img
              src={"/assets/icons/icon-type-rock.svg"}
              alt="icon-type-rock"
              className="icon icon-type-rock"
            />
          </div>
          <p className="type-name">หิน</p>
        </button>

        <button className="btn btn-ghost btn-type btn-type-mortar">
          <div className="image-type">
            <img
              src={"/assets/icons/icon-type-mortar.svg"}
              alt="icon-type-mortar"
              className="icon icon-type-mortar"
            />
          </div>
          <p className="type-name">ปูน</p>
        </button>

        <button className="btn btn-ghost btn-type btn-type-sand">
          <div className="image-type">
            <img
              src={"/assets/icons/icon-type-sand.svg"}
              alt="icon-type-sand"
              className="icon icon-type-sand"
            />
          </div>
          <p className="type-name">ทราย</p>
        </button>
      </div>

      <div className="block-product-list">
        <div className="product-list-title-sort">
          <p className="product-list-title">รายการสินค้า</p>
          <div className="product-list-sort">
            <div className="sort-column btn-sort">
              <button
                className="btn btn-ghost"
                onClick={() => setActiveSortRows(false)}
              >
                <img
                  src={`/assets/icons/sort-column-${activeSortRows ? "black" : "orange"
                    }.svg`}
                  alt="sort-column-black"
                  className="icon sort-column-black"
                />
              </button>
            </div>
            <hr className="line" />
            <div className="sort-rows btn-sort">
              <button
                className="btn btn-ghost"
                onClick={() => setActiveSortRows(true)}
              >
                <img
                  src={`/assets/icons/sort-rows-${activeSortRows ? "orange" : "black"
                    }.svg`}
                  alt="icon-type-sand"
                  className="icon icon-type-sand"
                />
              </button>
            </div>
          </div>
        </div>
        <div
          className={`product-list ${activeSortRows ? "rows" : "column"} ${mockProducts.length === 0 ? "!block" : ""
            }`}
        >
          {mockProducts.length !== 0 ? (
            mockProducts.map((products: any, i: number) => (
              <div key={i} className="card card-product-home-page">
                <figure className="block-image-product">
                  <img
                    src="/assets/images/no-image-product.png"
                    alt="no-img-product"
                    className="rounded-xl w-full shadow-[0_2.7px_32.4px_0px_#0000001A]"
                  />
                </figure>
                <div className="card-body">
                  <h1 className="card-title">
                    เหล็ก WF-BEAM 300x150x6.5x9มม. 9 เมตร SS400
                  </h1>
                  <h1 className="unit-price">1,500-1,900 /เส้น</h1>
                  <div className="card-actions mt-3">
                    <button className="btn btn-primary btn-product-detail">
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-product">No product</div>
          )}
        </div>
      </div>
      <div className="block-pagination join">
        <button className="join-item btn btn-pagination-prev">
          <img
            src="/assets/icons/icon-prev-orange.svg"
            className="icon-prev-orange"
            alt="icon-prev-orange"
          />
        </button>
        <input
          className="join-item btn btn-pagination-numner btn-square"
          type="radio"
          name="options"
          aria-label="1"
        />
        <input
          className="join-item btn btn-pagination-numner btn-square"
          type="radio"
          name="options"
          aria-label="2"
          defaultChecked
        />
        <input
          className="join-item btn btn-pagination-numner btn-square"
          type="radio"
          name="options"
          aria-label="3"
        />
        <input
          className="join-item btn btn-pagination-numner btn-square max-[430px]:hidden"
          type="radio"
          name="options"
          aria-label="4"
        />
        <input
          className="join-item btn btn-pagination-numner btn-square max-[430px]:hidden"
          type="radio"
          name="options"
          aria-label="5"
        />
        <input
          className="join-item btn btn-pagination-numner btn-square max-[430px]:hidden"
          type="radio"
          name="options"
          aria-label="6"
        />
        <button className="join-item btn btn-pagination-next">
          <img
            src="/assets/icons/icon-next-orange.svg"
            className="icon-next-orange"
            alt="icon-next-orange"
          />
        </button>
      </div>
    </section>
  );
};

export default Home;
